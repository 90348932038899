@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	   only screen and (min--moz-device-pixel-ratio: 1.5),
	   only screen and (min-device-pixel-ratio: 1.5){
}

/* FIX OCULTAR BOTÓN MENÚ */
@media screen and (max-width: 2400px) and (min-width: 1141px){
    .btn-menu{
        display: none !important
    }   
 
    .mobile .btn-menu{
    	/*display: block !important*/
    }
} 
 


/* RESPONSIVE HORIZONTAL */

:root {
    
    /* colores */
    --color-fff: #fff;
    --color-eee: #eee;
    --color-ddd: #ddd;
    --color-ccc: #ccc;
    --color-999: #999;
    --color-666: #666;
    --color-333: #333;
    --color-000: #000;
    --color-gris: #1b1b15;
    --color-verde: #8b9e93;
    --color-rojo: #b84d4c;
    --color-gris-2: #a1a1a1;
    --color-placeholder: #808285;

    /* font */
    --font-1: 'Barlow', sans-serif;
    --font-2: 'Barlow Condensed', sans-serif;
    --font-3: 'Barlow Semi Condensed', sans-serif;
}




/* ----------------------------------------------------------------------*/

/* 1480
 
-------------------------------------------------------------------------*/
@media screen and (max-width: 1480px){
	


   
	/* #TEXTO's */
	body{
		font-size: .95rem
	}
	




	#logo small{
		font-size: 1rem
	}

	



	#main{
		padding-top: 90px !important
	}
	 

	.contenido{
		padding: 70px 0 0 0;
	}


	/* #slide */
		


		/* slide inicio */
		.slide-inicio .item{
			padding-top: 0
		}

			.slide-inicio .btn{
				padding: 10px
			}


			/* img logo */
			.slide-inicio h5{
				font-size: 1.3rem
			}
 	

	 	





	.col-lg-3 .box header h3{
		font-size: 1.1rem
	}





	#footer{
		padding: 60px 0 40px 0;
	}

		#footer .mt-5{
			margin-top: 0 !important;
		}








	/* LOGIN */
    .login #header .cont,
    .login #header .btn-menu-2{
   	
    }

	figure.perfil{
		width : 70px;
		height: 90px;
	}



}










/* ----------------------------------------------------------------------*/

/* 1366
 
-------------------------------------------------------------------------*/
@media screen and (max-width: 1366px){





	/* LOGIN */
    .login .col--right{
    	transform: translateX(-75px);
    }

	

    /* #TEXTO's */
	body{
		font-size: .9rem
	}


	#conoce i,
	#conoce .ico:before{
		width: 75px;
		height: 75px
	} 

	.col-md-4.logos-pie img{
		height: 50px
	}
	img.fic{
		height: 45px !important
	}
	.logos-pie img{
		height: 60px
	}

	.box header h3{
		
	}



}











/* ----------------------------------------------------------------------*/

/* 1280
 
-------------------------------------------------------------------------*/
@media screen and (max-width: 1280px){

	/* #col */
	.col-25{
		-ms-flex: 0 0 24.9998%;
		flex: 0 0 24.9998%;
		width: 24.9998%
	}

	


	/* LOGIN */
    .login .col--right{
    	transform: translateX(-80px);
    }




	/* slide */
	.prev, .next{
	 		width: 15px;
	 		background-size: contain
	 	}
	 	.prev{
	 		left:-12px
	 	}
	 	.next{
	 		right: -12px
	 	}


	.slide-inicio img.logo-slide{
		width: auto !important;
	}

	.slide-inicio h5{
		font-size: 1.25rem;
		margin: 20px 0 40px 0
	}

	.slide-inicio .tx{
		padding-top: 20px;
	}

	.botones-ch a,
	.botones-gr a{
		padding: 8px 5px;
	}

	/* # mapa */
	.mapa{
		height: 350px;
	}


	/* cabzal */
	.cabezal p{
		font-size: 1rem
	}



	/* LOGIN */




    /* moneda */
    .redes .ico,
    .redes .ico:before{
    	width: 30px;
    	height: 30px
    }
    .redes .ico-yt,
	.redes .ico-yt:before{
	    width: 65px
	}





	/* UPDATE JUNIO 2023 */


	/* menu */
	.menu ul li a{
		padding: 0 18px
	}

	.menu .sub-menu{
		padding-left: 18px
	}

	/* form */
	.form-search{
		width: 120px;
		left: -25px
	}

}










/* ----------------------------------------------------------------------*/

/* 1140
 
-------------------------------------------------------------------------*/
@media screen and (max-width: 1140px){

	


	.container,
	#header .center{
		padding: 0 30px;
	}




	/* #col */
	.col-25{
		-ms-flex: 0 0 24.9998%;
		flex    : 0 0 24.9998%;
		width   : 24.9998%
	}



	/* header */
	#header.open--submenu{
		height: 90px;
	}

		/* logo */
		#logo{
			height: 30px;
			transform: translateY(2px)
		}

		#logo img{
			height: 26px;
		}


		/* cont */
		#header .cont{
			max-width: inherit;
			width: calc(100% - 40px);
			grid-template-columns: 1fr;
			max-height: inherit;
		}

		#header .cont .fix{
			max-width: inherit;
			width: 100%;
			position: fixed;
			top: 60px;
			left:0;
			background: var(--color-gris);
			grid-template-columns: 1fr;
			padding: 0 30px 30px 30px;
			max-height: inherit;
			height: auto;
			display: flex;
			flex-direction: column-reverse;

			transform: translateY(-500px);
		}

		.visible #header .cont .fix{
			transform: translateY(0);
		}

			/* left */
			.col--left{
				justify-content: end;
				margin-top: 15px
			}
				.col--left nav{
					max-width: 200px;
					float: right;
				}


			/* center */
			.menu,
			.mobile .menu{
				position        : relative;
				top             : auto !important;
				bottom          : auto;
				left            : 0;
				width           : 100%;
				max-width       : 100%;
				height          : 0;
				align-items     : center;
				justify-content : center;
				visibility      : visible;
				height          : auto;
				opacity         : 1;
				transition      : all .4s ease;

				display: block;
			}

				.menu ul{
					display: block
				}

				.menu ul li{
					border-bottom: 1px solid #ffffff1a
				}

				.menu ul li,
				.menu ul li a {
					width: 100%;
					float: left;
					text-align: right;
				}

				.menu ul li a {
					padding: 10px 0
				}

				.menu ul li a:after{
					display: none
				}


				.menu .sub-menu{
					position: relative;
					display: none;
					opacity: 1;
					visibility: visible;
					float: left;
					text-align: right;;
				}



					.menu .sub-menu a{
						text-align: right
					}


			/* right */
			.form-search{
				margin-right: 15px;
				width       : 180px;
				right       : auto !important
			}
				

	/* btn menú */
    .btn-menu {
		display  : block !important;
		position : fixed;
		top      : 0;
		right    : 30px;
		margin   : auto;
		width    : 20px;
		height   : 30px;
		cursor   : pointer;
		z-index:  998;
    }
    .btn-menu:hover{
    	opacity: .6
    }

        .btn-menu span {
			position     : absolute;
			top          : 0;
			right        : 0;
			bottom       : 0;
			left         : 0;
			width        : 100%;
			height       : 1px;
			margin       : auto;
			border-radius: 0;
			transition   : background 0s .25s;
			background   : var(--color-gris-2)
        }

	        .btn-menu span:before,
	        .btn-menu span:after {
				position           : absolute;
				width              : inherit;
				height             : inherit;
				content            : '';
				transition-delay   : .25s, 0s;
				transition-duration: .25s, .25s;
				background         : var(--color-gris-2);
				border-radius      : 0;
	        }
	        .btn-menu span:before {
	            top: -8px;
	            transition-property: top, -webkit-transform;
	            transition-property: top, transform;
	        }
	        .btn-menu span:after {
	            bottom: -8px;
	            transition-property: bottom, -webkit-transform;
	            transition-property: bottom, transform;
	        }

        /* hover - activo */
        body.visible #flag + span {
            background: none;
        } 
        body.visible #flag + span:before {
            top: 0;
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
        }
        body.visible #flag + span:after {
            bottom: 0;
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
        }
        body.visible #flag + span:before,
        body.visible #flag + span:after {
            transition-delay: 0s, .25s;
        }
	


	
		/* LOGIN */

		.login .col--right{
			transform: translateX(-40px);
		}

		.login .btn-menu{
			transform: translateX(-50px);
		}

		figure.perfil{
			width: 50px;
			height: 90px;
		}

			figure.perfil img{
				width: 30px;
				height: 30px;
			}






	/* main */
	#main, 
	.inicio #main{
		padding-top: 90px !important;
	}


	/* cabezal */
	.cabezal{
		height: 90px
	}



	/* slide */
	.slide-inicio .prev{
		left: 5px;
		width: 15px;
	}
	.slide-inicio .next{
		right: 5px;
		width: 15px;
	}




	/* slide */
	.galeria .owl-carousel{
		padding: 0 30px !important;
		margin:  auto !important;
	}
	.galeria i{
		display: block !important;
		width: 20px;
	}
	.galeria .prev{
		left: 15px;
	}
	.galeria .next{
		right: 15px;
	}

	.galeria .owl-stage{
		padding-left: 0;  
	}




	/* vive fix */
   #vive .datos, .grid_vive .box .datos, 
   #cultura-sostenible .datos, .grid_cultura_sostenible .datos, 
   #cocrea .datos, 
   #aprende .datos, .grid_aprende .box .datos, .grid_cocrea .box .datos{
        height: 140px
    }







 
}	









/* ----------------------------------------------------------------------*/

/* 980
 
-------------------------------------------------------------------------*/
@media screen and (max-width: 980px){



	.fila-pro section{
		width: 100%;
		text-align: left;
		padding-left: 0;
		margin-top: 10px
	}

	.fila-pro figure{
		width: 48px;
		height: 48px
	}


	#registrar_user_form .col-12.mb-3.d-flex{
		display: block !important
	}

	#registrar_user_form img{
		float: left
	}
	#registrar_user_form input[type="file"]{
		margin-bottom: 30px
	}





	

}





/* ----------------------------------------------------------------------*/

/* 1024
 
-------------------------------------------------------------------------*/
@media screen and (max-width: 1024px){



	.crea--contenedor {
    	padding: 80px 120px
    }



}







/* ----------------------------------------------------------------------*/

/* 768
 
-------------------------------------------------------------------------*/
@media screen and (max-width: 768px){

	
	.col-25{
		-ms-flex: 0 0 33.3332%;
		flex    : 0 0 33.3332%;
		width   : 33.3332%;

	}


	/* #textos */
	body{
		font-size: .9rem
	}

	:root {
	
		 /* font size */
	    --h1: 32px;
	    --h2: 21px;
	    --h3: 21px;
	    --h4: 30px; /* destacado */
	    --h5: 20px;
	    --h6: 18px;
	    --p:  18px;
	    --p-notas: 22px;

	    --h1-tit-notas: 45px;
	    --h2-subtit-notas: 30px;

	    --tit-cabezal: 42px;
	    --subtit-cabezal: 24px;
	}


	.contenido{
		padding: 80px 0 0 0;
	}



	


	.form-search{
		width : 100%;
		float : left;
		margin: 0 0 15px 0;
	}


	#header figure img{
		max-width: 200px;
		float: right
	}



	/* tit */
	#cultura-sostenible .tit--home h2 {
		font-size: 2.2rem
	}
	#cultura-sostenible .tit--home h3 {
		font-size: 1.3rem
	}



	/* slide */
		
		.ratio-35{
			top: 0;
			left: 0;
			padding-top: 85% !important
		}
 
		
		.slide-inicio .tx{
			padding-top: 30px;
			width:100%;
			max-width: 355px;
			padding-top: 0
		}

			.slide-inicio img.logo-slide{
				max-height: 75px !important;
			}
			.botones-ch,
			.botones-gr{
				max-width: 355px
			}
			.botones-ch a{
				width: 75px; 
				padding: 8px 12px
			}

		.item .left, .item .right{
			width: 100%
		}




	#footer .col{
		width:100% !important;
		text-align: left;
		float: left;
		flex: 1 0 100%
	}


	.pedido-header strong{
	    font-size: .75rem
	}

	

	.slide-inicio img.pc {
            display: none;
        }
        .slide-inicio img.movil {
            display: block;
        }




       .col-md-6.logos-pie.fix{
		    justify-content: inherit
		}



	/* #box */
	.box header h3 {
		min-height: inherit;
	}



	/* #contenedor */
	.crea--contenedor {
		padding: 60px 40px
	}


		.crea--contenedor section.d--grid {
			grid-template-columns: 1fr
		}


		.crea--galeria {
			margin-bottom: 40px
		}
		.crea--galeria .gallery-thumbs .swiper-slide {
			width: 70px !important;
			height: 70px !important
		}

		aside .btn {
			height: auto
		}





}







/* ----------------------------------------------------------------------*/

/* 645
 
-------------------------------------------------------------------------*/
@media screen and (max-width: 645px){
	


	:root {

		 /* font size */
	    --h1: 30px;
	    --h2: 19px;
	    --h3: 19px;
	    --h4: 26px; /* destacado */
	    --h5: 18px;
	    --h6: 18px;
	    --p:  16px;
	    --p-notas: 18px;

	    --h1-tit-notas: 36px;
	    --h2-subtit-notas: 26px;

	    --tit-cabezal: 32px;
	    --subtit-cabezal: 18px;
	}

	.tit h1 {
		line-height: 30px
	}
	.tit h6 {
		line-height: 26px
	}

	.tit--home h2 {
		font-size: 26px;
	}
	.tit--home h3 {
		font-size: 18px
	}

	.crea--notas .tit h1 {
		line-height: 48px
	}
	.crea--notas .tit h2 {
		line-height: 34px
	}
	.crea--notas article p {
		line-height: 30px
	}

	article h3 {
		line-height: 28px
	}
	article p {
		line-height: 26px
	}

	



	#registrar_user_form img{
		width: 160px;
		height: 160px
	}
 	



	.slide-inicio h5{
		margin: 30px 0 ;
		font-size: 1.2rem
	}


		.slide-inicio .item .container{
			display: block;
		}


		.item .left, .item .right{
			display: block;
			width: 100%;
			max-width: inherit;
		}

			.slide-inicio .tx{
				max-width: inherit;
				padding-top: 30px;
			}

 

		/* slide galeria */
		.galeria .owl-stage{
			padding-left: 0 !important;
		}


 

	.logos-pie h6{
		margin-right: 15px;
	}

	.logos-pie img{
		height: 50px;
	}


	/* cabezal */
	.cabezal{
 		display:block;
 	}

 	.cabezal .container{
 		display: block
 	}


	/* fix */

	/* cabezal */
	.cabezal .container {
		display: flex
	}



	/* tit */


	/* box */
	.box header h3{
		font-size: 1.15rem
	}

		.etiqueta{
			padding: 4px 10px;
			font-size: .75rem
		}


	/* moneda */
	.cont-moneda{
		display: block;
	}
	.cont-moneda h6,
	.moneda{
		width: 100%
	}
	.cont-moneda h6{
		margin-bottom: 5px !important
	}

	#footer .col{
		text-align: left !important;
		margin-bottom: 15px !important
	}

	.redes{
		float: left;
		width: 100%;
		text-align: left
	}

	#footer nav a{
		width: 100%;
		float: left;
		text-align: left;
		margin-left: 0;
		padding-left: 0
	}




	.swiper-pagination{
		bottom: -1px !important
	}


	/* tab */
	.tab-link{
		width: 100% !important;
		border-radius: 15px !important
	}

		.tab-content section{
			padding: 15px !important
		}



	.crea--contenedor {
		padding: 30px 40px
	}

		.crea--contenedor .crea--col-left .sec-precios {
			margin-top: 60px
		}

		aside .datos,
		aside article {
			padding: 0 20px !important
		}


	/* play */
	button.play{
		width: 45px;
		height: 45px
	}




	.pedido-header strong{
	    font-size: .7rem
	}

	.col-id{
        width: 50px
    }
    .col-fecha{
        width: 120px;
    }
    .col-estado{
        width: 23%;
    }
    .col-monto{
        width: 23%;
    }
    .col-pedido{
        width: 80px;
    }
        .col-pedido button{
            width: 40px !important;
            padding: 5px 10px !important
        }

    .col-comp{
        width: 140px;
    }

    .pedido-item .item-col{
        padding: 5px 10px;
    }




    button.mute{
		width: 55px;
		height: 55px
	}
	
	

	 
}

	@media screen and (max-width: 645px) and (orientation: landscape){ 
		

	}







/* ----------------------------------------------------------------------*/

/* 580
 
-------------------------------------------------------------------------*/
@media screen and (max-width: 580px){
	



	#header .center,
	.slide-inicio .item .container{
		padding: 0 15px
	}



	body.embed .embed-content{
		min-width: inherit
	}

	.sec-precios label{
		display: block;
		border-bottom: 1px solid #ddd;
		padding-bottom: 10px
	}
	.sec-precios label span{
		font-size: .85rem;
	}
	.sec-precios .desc{
		/*width: 100% !important;*/
		transform: translateY(4px)
	}
	.sec-precios .monto{
		width: auto !important
	}

	.btn-terminar{
		width:100%
	}

	.fila-precio .precio-carrito{
		padding: 15px;
		text-align: center
	}


	/* #header */
	#header{
		height: 60px;
		padding: 5px 0
	}

		#header .center{
			grid-template-columns: 125px 1fr;
			transform: translateY(12px);
		}


		/* logo */
		#logo{
			
		}
			#logo,
			#logo img{
				height: 20px;
			}


		/* menu */
		.btn-menu{
			top: -2px;
			right: 15px;
		}

		#header .cont{
			width: calc(100% - 35px)
		}

			#header .cont .fix{
				top: 40px;
			}

		 
			/* #carrito */ 
			.btn-carrito span{ 
				width: 18px;
				height: 18px;
				font-size: .75rem
			}


			.btn--cuenta{
				font-size: .75rem !important;
				padding: 4px 8px;
				margin-left: 15px;
				border-radius: 4px;
			}

			.btn--entrar{
				font-size: .75rem !important;
			}

			.btn--buscador{
				display: none
			}

			.col--left{
				display: block
			}

			.col--right .form-search.pc{
			    display: none;
			}
			.form-search.pc.activo{
			    display: none;
			}
			.form-search.movil{
			    display: block;
			    left: auto !important;
			    position: relative;
			    margin: 15px 0 0 0
			}

		.col--right{
			top: 3px;
		}


		/* LOGIN */
		figure.perfil{
			height: 60px;
			top: -15px;
			right: 0
		}

			ul.perfil{
				top: 43px;
				right: 0;
			}




	/* tit */


	/* #main */
	#main, 
	.inicio #main{
		padding-top: 60px !important
	}




	/* slide */
	#slide-home .prev,
	#slide-home .next{
		display: none !important;
		opacity: 0 !important
	}

		
		.slide-inicio .tx{
			padding-top: 40px;
			max-width: 305px;
		}

			.slide-inicio img.logo-slide{
				max-height: 65px !important;
			}
			.botones-ch,
			.botones-gr{
				max-width: 305px
			}
			.botones-ch a{
				width: 65px !important; 
				padding: 5px 8px !important;
				font-size: .75rem !important;
				margin-right: 12px
			}
			.botones-gr a{
				padding: 5px 8px !important;
				font-size: .75rem !important;
				margin-right: 0
			}

			.slide-inicio h5{
				font-size: 1rem
			}




	/* box */
	.box header h3{
		
	} 

 

	.col-id{
        width: 25px 
    }
    .col-fecha{
        width: 23%;
    }
    .col-estado{
        width: 23%;
    }
    .col-monto{
        width: 23%;
    }
    .col-pedido{
        width: 50px;
    }
    .col-comp{
    	width: 110px
    }

    .pedido-header strong{
    	font-size: .65rem
    }
	.item-col{
		padding:3px !important;
		font-size: .7rem !important
	}
	

	.historial .sec-perfil .container{
		padding: 0
	}

	.col-pedido button{
		width: 30px !important;
		padding: 4px !important
	}

	section.cont-pedidos{
		padding: 15px !important
	}


	/* logos pie */
	.logos-pie{
		display: block;
	}
		.logos-pie h6{
			margin-right: 0;
			width: 100%;
			margin-bottom: 10px !important;
		}

		.logos-pie img{
			height: 45px;
			margin-left: 0;
			margin-right: 15px;
		}




		#footer .col-md-4:nth-of-type(2){
			margin: 30px 0;
		}

 

		.redes .ico{
			width : 30px;
			height: 30px;
		}
		.redes .ico-yt{
			width: 65px !important;
		}



		.btn-cuenta{
			margin-left: 10px
		}




		.fila-pro article{
			width: 100%;
			padding-left: 0;
			margin-top: 10px
		}


		.ps-5{
			padding-left: 15px !important
		}

		.sec-carrito .col-md-4.ps-5{
			margin-top: 30px
		}



    /* form perfil */
	#registrar_user_form{
		width:100%;
		max-width: inherit
	}

	.slide-inicio .item .container{
		display: flex;
		align-items: end;
	}

	/* slide home */
	.slide-inicio .tx{
		max-width: inherit;
		width: 100%;
		text-align: center;
		padding: 0 0 35px 0
	}

	.slide-inicio .tx *{
		text-align: center;
		float: none;
		display: inline-block;
		margin: auto
	}

		.slide-inicio p{
			padding: 10px 0 0 0
		}

		.slide-inicio h5{
			margin: 20px 0;
		}


	/*.botones-ch, .botones-gr{
		width: 100%;
		max-width: inherit
	}*/

	

	.box .btn{
		font-size: .9rem !important
	}



	/* fix slide */
	.item-1 .left{
		display: none !important
	}
	

	button.mute{
		width: 35px;
		height: 35px
	}



	#registrar_user_form img{
		width: 100px;
		height: 100px
	}


	.login #header .btn-menu-2{
		transform: translateX(-58px)
	}




	/* cabezal */
	.cabezal{
		height: 75px
	}

	.grid_impulsa .cabezal{
		height: 95px
	}








	.crea--galeria .gallery-thumbs .swiper-slide {
		width: 50px !important;
		height: 50px !important
	}

	.page_cultura_sostenible .embed-content article h4{
	    font-size: 20px;
	    padding: 20px 0;
	}
	.page_cultura_sostenible .embed-content article h4:before,
	.page_cultura_sostenible .embed-content article h4:after{
	    width: 100px;
	}

}
 



 




/* ----------------------------------------------------------------------*/

/* 360
 
-------------------------------------------------------------------------*/
@media screen and (max-width: 360px){


	#logo,
	#logo img{
		height: 18px;
	}

	.slide-inicio img.logo-slide{
		max-height: 45px !important
	}

	.botones-ch, .botones-gr{
		max-width: 294px
	}
	.botones-ch a{
		width: 60px;
		margin-right: 8px
	}



}











/* RESPONSIVE VERTICAL (si es necesario) */


/* # 700 */
@media screen and (max-device-height: 700px) {  


}