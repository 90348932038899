@charset "utf-8";

// Import variables and mixins.
@import "abstracts/abstracts";


/*--------------------------------------------------------------
# Generic
--------------------------------------------------------------*/

/* Normalize
--------------------------------------------- */
@import "generic/normalize";
 
/* Box sizing
--------------------------------------------- */ 
@import "generic/box-sizing";

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
@import "base/base";

/*--------------------------------------------------------------
# Layouts
--------------------------------------------------------------*/
// @import "layouts/content-sidebar"; // Uncomment this line for a sidebar on right side of your content.
// @import "layouts/sidebar-content"; // Uncomment this line for a sidebar on left side of your content.

/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/
@import "components/components";


/*-----------------------------------------------------------------------*/



@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;400;500;600;700&display=swap'); 


/* #BASE -----*/
 
html{
    scroll-behavior: auto !important;
}
/* root */
:root {
    
    /* colores */
    --color-fff: #fff;
    --color-eee: #eee;
    --color-ddd: #ddd;
    --color-ccc: #ccc;
    --color-999: #999;
    --color-666: #666;
    --color-333: #333;
    --color-000: #000;
    --color-gris: #1b1b15;
    --color-tx-gris: #58595b;
    --color-verde: #8b9e93;
    --color-rojo: #b84d4c;
    --color-gris-2: #a1a1a1;
    --color-placeholder: #808285;

    /* font family */
    --font-1: 'Barlow', sans-serif;
    --font-2: 'Barlow Condensed', sans-serif;
    --font-3: 'Barlow Semi Condensed', sans-serif;
    --font-4: 'Roboto Slab', serif !important;

    /* font size */
    --h1: 32px;
    --h2: 21px;
    --h3: 21px;
    --h4: 30px; /* destacado */
    --h5: 20px;
    --h6: 18px;
    --p:  18px;
    --p-notas: 22px;

    --h1-tit-notas: 45px;
    --h2-subtit-notas: 30px;

    --tit-cabezal: 42px;
    --subtit-cabezal: 24px;

}



*,
:before,
:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    font-weight: normal;
    font-style: normal;
    font-family: var(--font-1);
}


:focus,
.btn:focus,
.input:focus,
.textarea:focus,
.select:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: var(--color-gris)
}

[data-src] {
    cursor: pointer !important;
}

[data-src]:hover {
    opacity: 1;
}

a:not([href]):not([tabindex]) {
    cursor: default;
}

/* IE7 */
*:first-child+html body {
    overflow: visible;
}

/* selection */
::selection {
    background: var(--color-verde);
    color: var(--color-fff);
}

::-moz-selection {
    background: var(--color-verde);
    color: var(--color-fff);
}

::-o-selection {
    background: var(--color-verde);
    color: var(--color-fff);
}

::-ms-selection {
    background: var(--color-verde);
    color: var(--color-fff);
}

/* row */
.row-height [class*=col] {
    display: -webkit-flex;
    display: flex;
}

.row-reverse {
    flex-direction: row-reverse;
}

/* col's custom */
.col-25 {
    -ms-flex: 0 0 19.9998%;
    flex: 0 0 19.9998%;
    max-width: 19.9998%;
    padding: 0 15px
}

/* bg */
.bg-1 {
    background-color: var(--color-gris)
}
.bg-2 {
    background-color: var(--color-verde)
}
.bg-3 {
    background-color: var(--color-rojo)
}
.bg-4 {
    background-color: var(--color-gris-2)
}



/* display */
.d-flex {
    align-items: center;
}

.d--grid {
    display: grid
}

/* justify content */
.j-flex-end {
    justify-content: flex-end;
}

.j-space-between {
    justify-content: space-between;
}

.row-custom,
.slide,
main,
header,
section,
form,
aside,
nav,
time,
picture,
figure,
article, 
address,
footer {
    width: 100%;
    float: left;
}

/* RATIO */
[class*=ratio] {
    width: 100%;
    float: left;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ratio-110 {
    padding-top: 110% !important;
}

.ratio-100 {
    padding-top: 100% !important;
}

.ratio-75 {
    padding-top: 75% !important;
}

.ratio-56 {
    padding-top: 56.25% !important;
}

.ratio-35 {
    padding-top: 42% !important;
    max-height: 100vh !important
}

.ratio-25 {
    padding-top: 25% !important;
}

.ratio-20 {
    padding-top: 20% !important;
}

.ratio-15 {
    padding-top: 15% !important;
}

[class*=ratio] img {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: inherit;
    margin: auto;
    object-fit: cover;
}

[class*=ratio] .vimeo-video {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: inherit;
    margin: auto;
    object-fit: cover;
}

/* IMG */
img {
    width: auto;
    max-width: 100%;
    height: auto
}

figure {
    margin: 0;
    line-height: 0
}

figcaption {
    width: 100%;
    float: left
}




/*-----------------------------------------------------------------------*/




/* #BODY */
html,
body {
    height: 100%
}

body {
    overflow-x: hidden;
    font-size: 1rem;
    background-color: #ededee;
    color: var(--color-000);
    font-family: var(--font-1);
}




/*-----------------------------------------------------------------------*/




/* #CONT - CONTAINER */
#cont {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    padding-bottom: 354px;
}

.container {
    max-width: 1280px
}

.container-2 {
    max-width: 900px
}

.container-3 {
    max-width: 768px
}

.container-full {
    max-width: 100%;
    padding: 0
}




/*-----------------------------------------------------------------------*/




/* #TEXTO's */
h1 {
    font-size: var(--h1);;
}

h2 {
    font-size: var(--h2)
}

h3 {
    font-size: var(--h3)
}

h4 {
    font-size: var(--h4)
}

h5 {
    font-size: var(--h5)
}

h6 {
    font-size: var(--h6)
}


h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--color-gris);
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    margin-bottom: 20px;
}


/* #tit */

/* grl. */
.tit {
    margin-bottom: 80px;
}
    .tit h1 {
        font-size: var(--h1);
        font-weight: bold;
        line-height: 42px;
        margin-top: 0 !important;
    }
    .tit h6 {
        font-size: var(--h6);
        line-height: 30px;
        letter-spacing: 1px;
        margin-bottom: 0;
    }

/* notas */
.crea--notas .tit {
    margin-bottom: 40px;
}

    .crea--notas .tit h1 {
        font-size: var(--h1-tit-notas);
        text-align: center;
        font-weight: bold;
        line-height: 62px;
        letter-spacing: 1px;

    }
    .crea--notas .tit h2 {
        font-size: var(--h2-subtit-notas);
        text-align: center;
        line-height: 42px;
        color: var(--color-tx-gris);
        font-weight: 400;
        margin-bottom: 0;

    }

/* home */
.tit--home {
    text-align: center;
    margin-bottom: 0;
}
    
    .tit--home h2 {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 0;
    }
    .tit--home h3 {
        font-size: 21px;
        color: var(--color-gris-2);
        margin-bottom: 0;
    }

    /* home - cultura sostenible*/
    #cultura-sostenible .tit--home h2 {
        font-size: 2.8rem;
        color: var(--color-333);
        font-weight: 800 !important;
        line-height: 1;
    }
    #cultura-sostenible .tit--home h3{
        color: var(--color-333);
        line-height: 1;
        font-size: 1.69rem
    }



.tit.text-left * {
    text-align: left !important;
}
.tit.blanco * {
    color: var(--color-fff);
}


/* tx */
p {
    line-height: 1.5;
}
a {
    cursor: pointer;
    text-decoration: none;
    color: var(--color-verde);
}

article.text-center * {
    text-align: center;
}

article.text-left * {
    text-align: left;
}

article.text-right * {
    text-align: right;
}

article.text-justify * {
    text-align: justify;
}

.roboto * {
    font-family: 'Roboto Slab', serif !important;
}

article h1 strong,
article h2 strong,
article h3 strong,
article h4 strong,
article h5 strong,
article h6 strong {
    font-weight: 600
}

article h1,
article h2,
article h3,
article h4,
article h5,
article h6 {
    width: 100%;
    float: left;;
}

    article h2 {
        font-size: var(--h2);
        font-weight: bold;
        line-height: 30px;
    }
    article h3 {
        font-size: var(--h3);
        font-weight: 600;
        line-height: 30px;
        color: var(--color-tx-gris);
    }
    article h4 {
        color: var(--color-tx-gris);
        width: 100%;
        float: left;
        padding: 40px 0;
        margin: 20px 0;
        text-align: center;
    }
    article h4:before,
    article h4:after{
        content: '';
        width: 150px;
        height:1px;
        background-color: #58595b;
        position: absolute;
        left:0;
    }
    article h4:before{
        top: 0
    }
    article h4:after{
        bottom: 0 
    }

    article p {
        width: 100%;
        float: left;
        color: var(--color-tx-gris);
        font-size: var(--p);
        line-height: 30px;
        margin-bottom: 12px !important;
    }

    /* notas */
    .crea--notas article p {
        font-size: var(--p-notas);
        font-weight: 300;
        line-height: 38px;
        text-align: left;
    }





article p+h1,
article p+h2,
article p+h3,
article p+h4,
article p+h5,
article p+h6 {
    margin-top: 30px
}

article ul {
    width: 100%;
    float: left;
    margin-bottom: 15px;
    padding-left: 18px;
    margin-left: 0
}
article ul li {
    color: var(--color-gris);
    width: 100%;
    float: left;
    line-height: 1.35;
    list-style: inherit !important
}

.crea--notas hr + h3{
    width: 100%;
    float: left;
}




/*-----------------------------------------------------------------------*/




/* #ICONO's */
.ico,
.ico:before,
.ico:after {
    content: '';
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center
}

.ico.ico-abs:before,
.ico.ico-abs:after {
    content: '';
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: auto
}


.ico-ok:before {
    background-image: url(../images/ico-ok.svg?2);
}

.ico-error:before {
    background-image: url(../images/ico-error.svg?2);
}

.ico-fb:before {
    background-image: url(../images/ico-fb.svg?2);
}

.ico-insta:before {
    background-image: url(../images/ico-insta.svg?2);
}

.ico-tw:before {
    background-image: url(../images/ico-tw.svg?2);
}

.ico-yt:before {
    background-image: url(../images/ico-yt.svg?2);
}

.ico-correo:before {
    background-image: url(../images/ico-correo.svg?2);
}

.ico-lupa:before {
    background-image: url(../images/ico-lupa.svg?2);
}

.ico-play:before {
    background-image: url(../images/ico-play.svg?2);
}

.ico-carrito:before {
    background-image: url(../images/ico-carrito.svg?2);
}

.ico-usuario:before {
    background-image: url(../images/ico-usuario.svg?2);
}

.ico-like:before {
    background-image: url(../images/ico-like.svg?2);
}

.ico-prueba:before {
    background-image: url(../images/ico-prueba.svg?2);
}

.ico-carrito-precio:before {
    background-image: url(../images/ico-carrito-precio.svg?2);
}

.ico-calendario:before {
    background-image: url(../images/ico-calendario.svg?2);
}

.ico-horario:before {
    background-image: url(../images/ico-horario.svg?2);
}

.ico-ubicacion:before {
    background-image: url(../images/ico-ubicacion.svg?2);
}

.ico-aportar:before {
    background-image: url(../images/ico-aportar.svg?2);
}

.ico-lupa-aside:before {
    background-image: url(../images/ico-lupa-aside.svg?2);
}

.ico-regalo:before {
    background-image: url(../images/ico-regalo.svg?2);
}

.ico-wp:before {
    background-image: url(../images/ico-wp.svg?2);
}

.ico-candado:before {
    background-image: url(../images/ico-candado.svg?2);
}



.ico-fb-comp:before {
    background-image: url(../images/ico-fb-comp.svg?2);
}
.ico-tw-comp:before {
    background-image: url(../images/ico-tw-comp.svg?2);
}
.ico-wp-comp:before {
    background-image: url(../images/ico-wp-comp.svg?2);
}
.ico-correo-comp:before {
    background-image: url(../images/ico-correo-comp.svg?2);
}




.ico-aprende:before {
    background-image: url(../images/ico-aprende.svg?2);
}
.ico-curaduria:before {
    background-image: url(../images/ico-curaduria.svg?2);
}
.ico-vive:before {
    background-image: url(../images/ico-vive.svg?2);
}
.ico-cocrea:before {
    background-image: url(../images/ico-cocrea.svg?2);
}
.ico-comunidad:before {
    background-image: url(../images/ico-comunidad.svg?2);
}









/*-----------------------------------------------------------------------*/




/* #BOTONES */
.btn {
    width: auto;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
    padding: 8px 30px;
    background-color: transparent;
    color: var(--color-fff) !important;
    font-size: .95rem;
    border: none;
    transition: all .2s ease !important;
    font-weight: 600;
    border: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-family: 'Barlow Condensed', sans-serif !important;
    letter-spacing: 0.03em;
    line-height: 1.1
}
aside .btn {
    width: 100%;
    margin: 0;
}

.btn:before {
    display: none; 
}


.btn.color-1 {
    background-color: var(--color-gris) !important;
    border: 1px solid var(--color-gris) !important
}

.btn.color-2 {
    background-color: var(--color-verde) !important;
    border: 1px solid var(--color-verde) !important
}

.btn.color-3 {
    background-color: var(--color-rojo) !important;
    border: 1px solid var(--color-rojo) !important
}

.btn.color-4 {
    background-color: var(--color-gris-2) !important;
    border: 1px solid var(--color-gris-2) !important
}

.btn:hover {
    background: var(--color-000) !important;
    color: var(--color-fff) !important;
    border:1px solid var(--color-000) !important
}



/* clear */
.btn-clear {
    position: absolute !important;
    right: 10px;
    top: 3px;
    height: 30px;
    width: 30px;
    background-color: var(--color-fff);
    border-radius: 50px;
    border: 2px solid var(--color-fff);
    padding: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.btn-clear:hover {
    border: 2px solid #ccc;
    background-color: var(--color-fff);
}

.btn-clear:after {
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    content: "\274c";
    font-size: 14px;
    color: var(--color-000);
    line-height: 25px;
    text-align: center;
}


/* cuenta */
.btn-cuenta {
    float: right;
    margin-left: 20px;
    border: 1px solid var(--color-rojo) !important;
    height: 30px;
    padding: 5px 30px;
}

.btn-cuenta:hover {
    border: 1px solid var(--color-fff) !important;
}


/* entrar */
.btn-entrar {
    margin-left: 15px;
    color: var(--color-gris-2) !important;
    border: 1px solid var(--color-666) !important;
    padding: 5px 30px;
    height: 30px;
}


/* carrito */
.btn-carrito {
    width: 30px;
    height: 30px;
    float: right;
}

.btn-carrito:before {
    width: 30px;
    height: 30px;
    display: inline-flex;
}

.btn-carrito span {
    color: var(--color-fff);
    background-color: var(--color-rojo);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: .2px;
    left: -4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
}

.btn-carrito span.hide {
    display: none;
}

.btn-carrito.activo span {
    animation-name: carrito;
    animation-duration: 4s;
}

@keyframes carrito {
    0% {
        transform: scale(1.1);
    }

    25% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}



/* terminar */
.btn-terminar {
    background-color: var(--color-verde) !important;
    float: right;
    margin-top: 15px;

}


/* botones ch */
.botones-ch {
    align-items: center;
    justify-content: space-between;
}

.botones-ch a {
    width: 85px;
    padding: 8px;
} 

.botones-ch a:last-child {
    margin-right: 0;
}


.botones-gr a{
    width:auto;
    max-width: inherit
}
.botones-gr .btn-slide-1 {
    width: 395px;
}


/* cancelar ordfen */
.cancel-order{
    float:right;
    margin-top: 15px
}

body.carrito .sec-carrito #entrar section img {
    display: none;
}

.sec-carrito .popup-fbx {
    border-radius: 15px !important;
}

.sec-carrito .cont-producto {
    border: none !important;
}



/*-----------------------------------------------------------------------*/




/* #HEADER - #MAIN - #FOOTER */

/* header */
#header {
    padding: 15px 0;
    position: fixed;
    z-index: 99999999;
    transition: all .3s ease;
    background-color: var(--color-gris);
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, .2);
    height: 90px;
}
#header.open--submenu{
    height: 188px;
}



#header * {
    color: var(--color-fff)
}

#header .center {
    width: 100%;
    padding: 0 15px;
    max-width: 1280px;
    margin: auto;

    display: grid;
    align-items: flex-start;
    grid-template-columns: 185px 1fr;
    grid-gap: 15px;
    transform: translateY(15px);
}

    /* #logo */
    #logo {
        margin: auto;
        width: 185px;
        height: 30px; 
        line-height: 1;
        z-index: 997;
        display: flex;
        transition: all .3s ease;
        transform: translateY(0);
    }

    #logo a {
        display: block;
        float: left;
        line-height: 0
    }

    #logo span {
        display: none
    }

    #logo img {
        height: 30px
    }

    #logo small {
        color: var(--color-fff);
        width: 100%;
        float: left;
        font-size: 1.4rem;
    }

    

    #header .cont {
        width: auto;
        height: 100%;
        max-height: 60px;
        float: right;
        display: grid;
        grid-template-columns: 1fr 350px
    }

        #header .cont .fix{
            display: grid;
            grid-template-columns: 175px 1fr;
        }


        .col--left{
            text-align: center;
            display: flex;
            align-items: center;
        }

            .col--left nav{
                width:100%;
                border-radius: 4px;
                border: 1px solid var(--color-gris-2);
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 2px 8px;
            }

            .col--left nav a{
                color: var(--color-gris-2) !important;
                font-family: "Barlow Semi Condensed" !important;
                font-weight: 500;
                font-size: .9rem !important;
                margin: auto;
            }
            .col--left nav a.activo,
            .col--left nav a:hover{
                color: #fff !important
            }

            .col--left nav span{
                margin: auto;
                color: var(--color-gris-2);
                display: flex;
                font-family: "Barlow Semi Condensed" !important;
                font-size: .8rem !important
            }





        .col--center{
            text-align: center;
            display: flex;
            align-items: center;
        }


            /* #MENÚ's' */
            .menu {
                width: 100%;
                max-width: 100%;
                height: auto;
                float: none;
                margin: 0;
                right: auto;
                display: flex;
                align-items: center;
            }

            .menu ul {
                height: 100%;
                display: block;
                width: auto;
                margin: 0 auto;
                right: 0;
                display: flex;
                align-items: center;
                padding: 0
            }

            .menu ul li {
                width: auto;
                float: left;
                margin-bottom: 0;
                padding: 0;
                margin: 0;
                list-style: none;
            }

            .menu ul li a {
                width: auto;
                float: left;
                text-decoration: none;
                padding: 0 23px;
                cursor: pointer;
                text-align: center;
                color: var(--color-gris);
                text-transform: capitalize;
                font-weight: 500;
                font-size: .9rem;
                font-family: 'Barlow Semi Condensed', sans-serif;
            }

            .menu ul li a:after {
                content: '';
                width: 1px;
                height: 14px;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                background: var(--color-fff)
            }

            .menu ul li:last-child a:after,
            .menu ul li[data-pagina="contacto"] a:after {
                display: none
            }


            .menu ul li a:hover,
            .menu ul li a:hover:not([href]):not([tabindex]) {
                color: var(--color-rojo) !important
            }


            #btn--sub-menu{
                width:15px;
                height:15px;
                background-image: url(../images/ico-btn-submenu.svg);
                background-position: center;
                background-size: contain;
                background-repeat:  no-repeat;
                margin-left: 10px; 
                cursor: pointer
            }
            #btn--sub-menu.activo{
                transform: rotate(180deg);
            }



            /* hover */
            .menu ul li.activo .sub-menu li a:hover {
                color: var(--color-fff)
            }

            .menu ul li .sub-menu li a:hover {
                color: var(--color-fff);
                background-color: rgba(0, 0, 0, .3);
            }

            /* submenú */
            .menu .sub-menu {
                width: 100%;
                min-width: 150px;;
                height: auto;
                position: absolute;
                top: 100%;
                left: 0;
                padding: 5px 0 0 23px;
                display: block;
                visibility: hidden;
                opacity: 0;
            }

            .menu .sub-menu a {
                width: 100%;
                float: left;
                padding: 0 0 5px 0;
                margin: 0;
                text-align: left;
                word-break: break-all;
                white-space: inherit;
                font-size: .85rem;
                font-family: 'Barlow Condensed', sans-serif;
                font-weight: 500 !important;
            }
            .menu .sub-menu a:after{
                display: none !important;
            }


            /* abrir sub menu */
            .sub-menu.visible--submenu{
                opacity: 1;
                visibility: visible;
                display: block !important;
                height: auto
            }


            @media only screen and (max-width: 3000px) and (min-width: 1141px){

                .menu ul li:hover .sub-menu{
                    opacity: 1;
                    visibility: visible;
                    display: block;
                    height: auto
                }

            }

            .menu ul li:hover a,
            .menu ul li a:focus {
                background-color: var(--color-gris);
                color: var(--color-fff)
            }




            /* hover - activo */
            .menu li a:hover,
            .menu .activo a {
                color: var(--color-000)
            }





        .col--right{
            display: flex;
            align-items: center;
            justify-content: end
        }

            /* buscador */
            .btn--buscador{
                width:22px;
                height:22px;
                background-image: url(../images/ico-lupa.svg);
                background-size: 18px;
                background-repeat: no-repeat;
                background-position: center;
                float:left;
                margin-right: 8px;
                cursor: pointer;
            }
            .btn--buscador.activo{
                background-image: url(../images/ico-cerrar-form.svg);
            }


            /* carrito */
            .btn--carrito{
                width: 22px;
                height: 22px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                float: left;
                margin-right: 16px;
                cursor: pointer;
            }

                .btn--carrito:before{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-size: contain;
                    top: 0;
                    left: 0
                }

                .btn--carrito .hide{
                    position: absolute;
                    background:red;
                    font-size: .7rem;
                    font-family: "Barlow Semi Condensed" !important;
                    font-weight: 500;
                    color: #fff;
                    top: -5px;
                    right: -5px;
                    border-radius: 50%;
                    width: 15px;
                    height:15px;
                    display: flex;
                    align-items: center;
                    justify-content: center
                }


            /* entrar */
            .btn--entrar{
                color: var(--color-gris-2) !important;
                font-family: "Barlow Semi Condensed" !important;
                font-weight: 500;
                font-size: .9rem !important;
            }

            .btn--entrar:hover{
                color: #fff !important;
            }


            /* crear */
            .btn--cuenta{
                font-family: "Barlow Semi Condensed" !important;
                background-color: var(--color-rojo);
                color: #fff;
                padding: 6px 15px;
                border-radius: 7px;
                margin-left: 25px;
                line-height: 1;
                font-weight: 500;
                font-size: .9rem !important;
                border: 1px solid var(--color-rojo);
            }

            .btn--cuenta:hover{
                background-color: #000 !important;
                border: 1px solid #fff !important
            }





/* main */
#main {
    padding-top: 90px;
}






/* footer */
#footer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 120px 0 60px 0
}

/* col */
footer .col-md-4.text-center {
    display: flex;
    align-items: center;
}

footer .col-md-4.text-center a {
    margin: 0 auto;
}
footer .col-md-4.text-center a:hover{
    text-decoration: underline
}

/* tx */
#footer * {
    color: var(--color-gris-2);
}

#footer h6 {
    margin-bottom: 10px
}

#footer p {
    font-size: .8rem
}


/* nav */
#footer nav {
    margin-top: 20px;
    padding-top: 15px;
    border-top: 1px solid var(--color-gris-2);
    text-align: right;
}

#footer nav a {
    margin-left: 20px;
    text-decoration: underline;
}

#footer nav a:hover {
    text-decoration: none
}



/* logos */
.logos-pie {
    display: flex;
    align-items: center
}



.logos-pie h6 {
    float: left;
    width: auto;
    margin-bottom: 0 !important;
    margin-right: 10px;
    color: var(--color-333) !important;
}

.logos-pie img {
    height: 70px;
    margin-left: 15px;
    transition: all .3s ease;
}
img.fic{
     mix-blend-mode: multiply;
     height: 55px !important
}

.logos-pie a:hover img {
    filter: grayscale(100%);
}

.col-md-6.logos-pie img{
    height: 60px
}

.col-md-6.logos-pie.fix{
    justify-content: end
}




/*-----------------------------------------------------------------------*/






/* #SLIDER's */
.owl-carousel,
.owl-carousel .owl-stage-outer,
.owl-carousel .owl-stage,
.owl-carousel .owl-item,
.owl-carousel .item,
.owl-carousel .item-video {
    height: 100%;
}

    .owl-carousel .owl-video {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    .owl-carousel .owl-video-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }


    .owl-controls,
    .owl-dots {
        display: none;
    }


    /* fix */
    .col-12.swiper{
        padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
        padding-left: calc(var(--bs-gutter-x) * 0.5) !important
    }

    .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        opacity: 1;
        margin: 0 7px !important;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid  rgba(255,255,255,.6)
    }
    .swiper-pagination-bullet-active {
        background: rgba(255, 255, 255, 0.8);
    }


    /* nav's */
    .prev,
    .next {
        position: absolute;
        top: 0;
        width: 30px;
        height: 100%;
        font-size: 0;
        z-index: 907;
        cursor: pointer;
        display: flex;
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transform: translateY(0);
    }

    .prev {
        background-image: url(../images/prev1.svg);
        left: -35px;
    }
    .next {
        background-image: url(../images/next1.svg);
        right: -35px
    }
    .prev.color-0 {
        background-image: url(../images/prev0.svg);
        left: 0
    }
    .next.color-0 {
        background-image: url(../images/next0.svg);
        right: 0
    }
    .prev.color-1 {
        background-image: url(../images/prev1.svg);
        left: 0
    }
    .next.color-1 {
        background-image: url(../images/next1.svg);
        right: 0
    } 

    .slide-inicio .prev,
    .slide-inicio .next {
        width: 20px;
        opacity: 0;
    }
 
    .slide-inicio .prev{
        left: 15px; 
    }
    .slide-inicio .next{
        right: 15px;
    }

    /* hover */
    .slide-inicio:hover .prev,
    .slide-inicio:hover .next{
        opacity: .7 !important;
    }


    /* dots */
    .owl-dots {
        position: absolute;
        width: 100%;
        height: 20px;
        bottom: 15px;
        right: 30px;
        margin: auto;
        text-align: center;
        z-index: 9090
    }

    .owl-dots {
        width: 100%;
        text-align: center
    }

    .owl-dot {
        width: 12px;
        height: 12px;
        margin: 0 7px;
        background: var(--color-000);
        transition: ease-in 0.4s;
        float: none;
        display: inline-block;
        border:1px solid rgba(255,255,255,.5)
    }

    /* activo */
    .owl-dot.active {         
        background: #DA251C
    }

   


  
    /* item */
    .slide-inicio .item {
        display: flex;
        align-items: center;
        justify-content: center;
    }
 
    /* ratio */ 
    .slide-inicio .ratio-35{ 
       /* position: absolute;*/ 
        top: 0;
        left: 0
    } 
  
    /* container */
    .slide-inicio .item .container {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        height: 100%;
        z-index: 10;
        display: inline-flex;
    }
 
        /* tx */
        .slide-inicio .tx {
            z-index: 901;
            margin: auto 0;
            padding: 0;
            width: 100%;
            height: auto;
            text-align: left;
            margin: 0;
            width: 100%;
            max-width: 395px;
        }

            .slide-inicio h1,
            .slide-inicio h2,
            .slide-inicio h3,
            .slide-inicio h4,
            .slide-inicio h5,
            .slide-inicio h6,
            .slide-inicio p{
                padding: 0;
                margin: 0 0 12px 0;
                font-weight: normal;
            }
            

            /* custom (a partir del 2do banner) */
            .slide-inicio .item-custom p {
                width: auto;
                padding: 0;
                display: inline-block;
            }

            .slide-inicio .item-custom a {
                padding: 8px 12px;
                border-radius: 6px;
                background-color: var(--color-verde);
                color: var(--color-fff);
                text-decoration: none;
                margin-top: 10px; 
                display: inline-block;
                margin-right: 15px;
            }

            /* botones */
            .botones-ch {
                align-items: center;
                justify-content: space-between;
            }
                .botones-ch a {
                    width: 85px;
                    padding: 8px;
                    margin-right: 15px;
                }
                .botones-ch a:last-child {
                    margin-right: 0;
                }

            

        /* video */
        .slide-inicio video,
        .slide-inicio .video_inicio{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover
        }

        .video_inicio > div{
            padding: 0 !important;
            width: 100% !important;
            height: 100% !important
        }
        .gal-popup .video_embed{
            position: absolute;
            top: 0; 
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover
        }

        /* btn play */
        button.play {
            width: 70px;
            height: 70px;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            z-index: 997 !important;
            background: transparent; 
            border: 0 !important;
            padding: 0 !important;
            background-image: url(../images/btn-play.svg);
            background-repeat: no-repeat;
        }
        button.play:hover {
            background-color: transparent !important;
            opacity: .7;
            transform: scale(1.1);
        }
        button.play.click {
            opacity: 0;
        }
        button.play.click:hover {
            opacity: 1;
            background-image: url(../images/btn-pause.svg);
        }

        /* btn mute */
        button.mute {
            width: 70px;
            height: 70px;
            position: absolute;
            right: 40px;
            bottom: 40px;
            margin: auto;
            z-index: 997 !important;
            background: transparent; 
            border: 0 !important;
            padding: 0 !important;
            background-image: url(../images/btn-mute.svg);
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 0;
            transition: all .2s ease;
        }
        button.mute:hover,
        .slide-inicio .ratio-35:hover button.mute{
            background-color: transparent !important;
            opacity: 1;
        }
        button.mute:hover{
            transform: scale(1.1);
        }

        button.mute.click {
            opacity: 0;
        }
        button.mute.click:hover {
            opacity: 1;
            background-image: url(../images/btn-volumen.svg);
        }

        .slide-inicio img.movil {
            display: none;
        }

        .slide-inicio .d-flex {
            align-items: center;
        }

        .item .left,
        .item .right {
            display: flex;
            align-items: center;
            width: 49.9998%;
            float: left;
        }


        /* fix colores */
        .item.color-0 {
            background-color: #dadedc;
        }

        .item.color-1 {
            background-color: var(--color-666);
        }

        .item.color-0 * {
            color: var(--color-000);
        }

        .item.color-1 * {
            color: var(--color-fff);
        }

        .item.color-0 .btn-slide-right {
            color: var(--color-000);
            background-color: var(--color-fff);
        }

        .item.color-1 .btn-slide-right {
            color: var(--color-fff);
            background-color: var(--color-000);
        }




    /* galerias home */
    .galerias .swiper-wrapper{
        
    }

        /* item */
        .galerias .swiper-wrapper .swiper-slide .box{
            /*height: 100%*/
        }




    /* galeria novedades */
    .galeria{
        border-radius: 0 !important
    }
    .novedades .galeria i {
        width: 20px;
    }

    .novedades .galeria .prev {
        left: 5px;
    }

    .novedades .galeria .next {
        right: 5px;
    }







/*-----------------------------------------------------------------------*/




/* #FORM's */

/* fix ios */
select,
textarea,
input,
input[type="text"],
input[type="button"],
input[type="submit"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -op-appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0; 
}

.form-control,
textarea{
    margin-bottom: 10px;
    width: 100%;
    float: left;
    background-color: var(--color-fff) !important;
    color: var(--color-666);
    border: none;
    padding: 5px 10px !important;
    margin-bottom: 15px;
    border-radius: 6px !important;
    color: var(--color-gris-2);
    border:1px solid #ccc
}

label,
input,
textarea,
select {
    width: 100%;
    float: left;
}

/* textarea */
textarea.form-control,
textarea {
    height: 100px
}

/* select */
select {
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -op-appearance: none;
    background-image: url(../images/ico-select.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-color: transparent;
    background-size: 9px
}

/* focus */
select:focus {
    background-image: url(../images/ico-select.svg);
    border: 1px solid var(--color-666);
}

input:focus,
textarea:focus {
    outline: 0;
    border-color: var(--color-fff)
}


/* checkbox - radio custom */
input[type="checkbox"],
input[type="radio"] {
    display: none;
}

label.check,
label.radio {
    width: auto;
    margin-right: 35px;
    background-repeat: no-repeat;
    padding-left: 22px;
    background-size: 16px;
    cursor: pointer;
    margin: 5px 0;
    background-position: 0;
}

label.check {
    background-image: url(../images/checkbox.svg);
}

input[type='checkbox']:checked+label {
    background-image: url(../images/checkbox-checked.svg) !important;
}

label.radio {
    background-image: url(../images/radio.svg);
}

input[type='radio']:checked+label {
    background-image: url(../images/radio-checked.svg) !important;
}

/* submit */

#main form button {
    float: right;
   /* background-color: var(--color-verde) !important;
    color: var(--color-fff) !important;
    padding: 15px 60px */
}/*
#main form button:hover{
    background-color: var(--color-fff) !important;
    color: var(--color-verde) !important;
    border:1px solid var(--color-verde) !important
}
*/
.verif {
    display: none;
}

/*
    form input:focus:invalid,
    form textarea:focus:invalid{
        border:1px solid var(--color-eee);
    }

    .required input:focus:valid,
    .required input:valid,
    .required textarea:focus:valid,
    .required textarea:valid{
        border    :1px solid var(--color-eee);
        background:#E7F3DD
    }

    /* autocompletar
    input:-webkit-autofill {
        -webkit-box-shadow     : 0 0 0px 1000px var(--color-eee)eee inset;
        -webkit-text-fill-color: #19181D;
    }
    */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--color-666);
    box-shadow: 0 0 0px 1000px #f5f5f5 inset;
    -webkit-box-shadow: 0 0 0px 1000px #f5f5f5 inset;
    transition: background-color 5000s ease-in-out 0s;
}

/* placeholder */
input::-webkit-input-placeholder {
    color: var(--color-praceholder);
    font-family: var(--font-1);
    font-weight: 400
}

input::-moz-placeholder {
    color: var(--color-praceholder);
    font-family: var(--font-1);
    font-weight: 400
}

input:-ms-input-placeholder {
    color: var(--color-praceholder);
    font-family: var(--font-1);
    font-weight: 400
}

input:-moz-placeholder {
    color: var(--color-praceholder);
    font-family: var(--font-1);
    font-weight: 400
}

input::-webkit-input-placeholder {
    color: var(--color-praceholder);
    font-family: var(--font-1);
    font-weight: 400
}

textarea::-webkit-input-placeholder {
    color: var(--color-praceholder);
    font-family: var(--font-1);
    font-weight: 400
}

textarea::-moz-placeholde {
    color: var(--color-praceholder);
    font-family: var(--font-1);
    font-weight: 400
}

textarea:-ms-input-placeholder {
    color: var(--color-000);
    font-family: var(--font-1);
    font-weight: 400
}

textarea:-moz-placeholder {
    color: var(--color-praceholder);
    font-family: var(--font-1);
    font-weight: 400
}

/* form contacto */
#contacto_form button{ 
    padding: 15px 60px;
    color: var(--color-fff);
    background-color: var(--color-rojo) !important;
    border: 1px solid var(--color-rojo) !important;
}

/* form contacto */
#contacto_form button:hover{
    color: var(--color-fff);
    background-color: var(--color-gris-2) !important;
    border: 1px solid var(--color-gris-2) !important;
}


/* form header */
.form-search {
    background: #343434;
    border-radius: 6px;
    overflow: hidden;
    height: 28px;
    width: 150px;

    position: absolute;
    left: -45px; 
}
.col--right .form-search.pc{
    display: none;
}
.form-search.pc.activo{
    display: block;
}
.form-search.movil{
    display: none;
}


.login .form-search {
    float: right;
    right: 470px;
}

.form-search .cont-input {
    background: #343434;
    width: 100%;
    float: left;
    border-radius: 6px;
    overflow: hidden;
    height: 30px;
}

.form-search .ico {
    width: 35px;
    height: 30px;
    top: 0;
    left: 0;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    display: none
}

.form-search .ico:before {
    width: 19px;
    height: 19px;
    display: inline-block;
}


.form-search input {
    float: right;
    width: 100%;
    background: #343434;
    border: none;
    height: 28px;
    color: var(--color-gris-2);
    font-size: .85rem !important;
    padding: 5px 10px

}



/* aside */
aside form {
    background-color: var(--color-fff);
    border-radius: 10px;
    height: 40px;
    border: 1px solid var(--color-fff);
    overflow: hidden;
    padding: 5px;
    margin-bottom: 20px
}

aside form input {
    background: var(--color-fff);
    float: right;
    width: calc(100% - 35px);
    border: 1px solid var(--color-fff);
}

aside form:before {
    content: '';
    width: 20px;
    height: 20px;
    top: 3px;
    left: 5px;
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../images/ico-lupa-aside.svg);
}


aside h6 {
    width: 100%;
    float: left;
    margin: 15px 0;
    color: var(--color-gris-2);
    text-transform: uppercase;
}

aside nav {
    margin-bottom: 30px
}

aside nav a {
    width: 100%;
    float: left;
    padding: 0;
    color: var(--color-gris-2)
}

aside nav a:hover {
    color: var(--color-rojo);
}




/* form precios */
.sec-precios {
    padding: 0 !important;
}

.sec-precios header {
    padding-bottom: 5px;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--color-000);
    font-weight: 600;
}

.sec-precios label {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
}

.sec-precios label:hover span {
    color: var(--color-000);
    font-weight: 600;
}

.sec-precios label:hover .btn {
    background-color: var(--color-fff);
    color: var(--color-verde) !important;
    border: 1px solid var(--color-verde) !important;
}

.sec-precios label span {
    font-size: 1rem;
    display: inline-flex;
    padding: 0 5px;
    color: var(--color-000);
    justify-content: space-between !important;


}

.sec-precios .desc {
   width: calc(100% - 100px) !important;
}

.sec-precios .monto {
    width: 250px;
    text-align: right
}

.sec-precios .cantidad {
    border: 1px solid var(--color-000);
    border-radius: 3px;
    width: 95px;
    display: inline-flex;
    align-items: center;
    height: 23px;
    position: absolute;
    right: 0;
    text-align: center;
    
}
.sec-precios label.radio.activo .cantidad {
    border: 1px solid var(--color-000);
}

    

.sec-precios .otro .cantidad:before{
    content: '$';
    color: var(--color-000);
    position: absolute;
    left: -20px;
}

.sec-precios .cantidad i {
    font-weight: 100;
    padding: 0 10px;
    font-style: normal
}
.sec-precios label.radio.activo .cantidad i {
    font-weight: 600;
}

.sec-precios .cantidad i:nth-of-type(1) {
    float: left;
}

.sec-precios .cantidad i:nth-of-type(1) {
    float: right;
}

.sec-precios .cantidad i:hover {
    color: var(--color-verde);
}

.sec-precios .cantidad div {
    width: 50px;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    font-weight: 400
}
.sec-precios label.radio.activo .cantidad div {
    font-weight: 600;
}

.sec-precios .cantidad span{
        text-transform: uppercase !important
    }

/*.sec-precios .btn{
        position        : absolute;
        right           : 0;
        background-color: var(--color-verde);
        color           : var(--color-fff) !important;
        padding         : 0 9px !important;
        font-size       : .75rem;
        font-weight     : 400;
        border-radius   : 3px;
        height: 23px;
        border: 1px solid var(--color-verde) !important;
    }*/




.sec-precios .monto.abs {
    position: absolute;
    right: 0;
    text-align: right;
    justify-content: flex-end;
}


.sec-precios button {
    // width: 80px;
    width: auto;
    float: right;
    background-color: var(--color-verde);
    color: var(--color-fff) !important;
    padding: 0 9px !important;
    font-size: .75rem;
    font-weight: 400;
    border-radius: 3px;
    height: 25px;
    border: 1px solid var(--color-verde) !important;
    margin-top: 20px;
}

.sec-precios button:before {
    content: '';
    width: 15px;
    height: 15px;
    background-color: red;
    float: left;
    margin-right: 5px;
    background-image: url(../images/ico-carrito-precio.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


.sec-precios input[type="radio"]:checked+label * {
    font-weight: 600;
}

.sec-precios input[type="radio"]:checked+label .cantidad:before {
    font-weight: 600;
}





/*-----------------------------------------------------------------------*/




/* #MSJ's */

.error {
    color: #c90000
}

.ok {
    color: #4d9b08
}

/* modal */
.modal.show .modal-dialog {
    text-align: center;
}

/* header */
.alert {
    margin: auto;
}

.modal-alert .modal-header {
    border: none;
}

.modal-alert .modal-title {
    text-align: center;
    width: 100%;
}

/* body */
.modal-alert .modal-body h6 {
    font-family: var(--font-1);
    font-size: 1.2rem;
}

/* ico */
.modal-alert .modal-title i {
    display: inline-block;
    margin: auto
}

.modal-alert .modal-title i:before {
    width: 50px;
    height: 50px;
    display: inline-block;
}

.alert p{
    margin: 0
}

/* simple */
.msj {
    width: 100%;
    float: left;
    padding: 15px;
    display: flex;
    align-items: center;
    margin: 10px 0;
    justify-content: center;
}

.msj:before {
    content: '';
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 28px;
    height: 28px;
    float: left;
    margin-right: 20px;
    float: left
}

/* ok */
.msj.ok {
    background-color: #eaffdb;
    border: 2px solid #4d9b08;
}

.msj.ok:before {
    background-image: url(../images/ico-ok.svg);
}


/* alerta */
.msj.pendiente {
    background-color: var(--color-fff)9d3;
    border: 2px solid #edda50;
}

.msj.pendiente:before {
    background-image: url(../images/ico-alerta.svg);
}


/* error */
.msj.error {
    background-color: #ffdbdb;
    border: 2px solid #c90000;
}

.msj.error:before {
    background-image: url(../images/ico-error.svg);
}


.msj strong {
    margin-left: 6px
}




/*-----------------------------------------------------------------------*/




/* #TAB's */
.tabs {
    margin-bottom: 0;

}

/* link */
.tab-link {
    width: auto;
    padding: 10px 30px;
    opacity: 1;
    color: #8c9c91 !important;
    cursor: pointer;
    list-style: none;
    float: left;
    cursor: pointer !important;
    display: inline-flex;
    align-items: center;
}

    .tab-link:before{
        content: '';
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-right: 5px;
    }

    .tab-link:hover{
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
    }
    .tab-link.activo:not(.locked){
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
        font-weight: bold;
    }

    
    body.aprende .tab-link.locked{
        cursor: default !important;
    }
    body.aprende .tab-link.locked:before{
        content: '';
        display:block !important;
        background-image: url(../images/ico-candado.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 22px;
        height: 22px;
    }
    .tab-link:before,
    body.aprende .tab-link:before {
        display: none !important;
    }


/* content */
.tab-content {
    width: 100%;
    visibility: hidden;
    opacity: 0;
    height: 0;
    margin: 0;
    float: left;
}



.tab-content section {
    background: var(--color-fff);
    padding: 0;
}



.tab-content aside section {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
} 

.aprende aside .datos{
    padding: 15px 0 0 0;
    margin-bottom: 0 !important
}
.aprende aside .ico{
    font-size: .865rem
}
.aprende aside article p{
    margin-bottom: 0
}


/* hover - activo */
.tab-link:not(.locked):hover,
.tab-link.activo:not(.locked) {
    opacity: 1 !important;
    background: var(--color-fff);
}

.tab-content.activo {
    opacity: 1;
    visibility: visible;
    height: auto;
}


.cocrea .tab-content.activo {
    background-color: var(--color-fff);
    padding: 15px 
}


/* tab 3 -  CONTENIDO  */
.tit-resumen{
    color: var(--color-fff);
    padding: 10px;
    border-radius: 10px;
    background: var(--color-verde);
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase
}




/*-----------------------------------------------------------------------*/




/* #ACORDEON */
.acordeon {
    padding-left: 0;
    margin: 30px 0;
}

/* link */
.item-acordeon {
    width: 100%;
    float: left;
    cursor: pointer;
    list-style: none;
    margin-bottom: 5px;
    padding: 1rem 0;
    border-top: 1px solid #ddd
}

.item-acordeon:after {
    content: '+';
    position: absolute;
    bottom: 6px;
    right: 1rem;
    margin: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    font-size: 1.8rem;
    font-weight: 400
}

/* content */
.acordeon .content {
    display: none;
    width: 100%;
    float: left;
    padding: 5px 0 !important;
}

/* hover - activo */
.item-acordeon.activo:after {
    content: '-'
}

.acordeon .content.activo {
    display: block
}


/* aprende aside*/
aside .item-unidad.acordeon{
    margin: 0 !important
}
aside .item-unidad .item-acordeon{
    color: var(--color-verde);
    padding: 5px 15px 5px 20px;
    margin: 0;
    border: none;
    font-size: .9rem
}
    aside .item-unidad .item-acordeon:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 12px;
        height: 12px; 
        background: transparent;
        border: 1px solid var(--color-verde); 
        border-radius: 50%;
        margin: auto; 
    }
    aside .item-unidad .item-acordeon.activo:before{
        background: var(--color-verde);
    }

    aside .item-unidad .item-acordeon:after{
        content: '';
        width: 12px;
        height: 12px;
        top: 0;
        right: 0; 
        bottom: 0;
        margin: auto;
        background-image: url(../images/ico-acordeon.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat
    }

    aside .item-unidad .item-acordeon.activo:after{
        transform: rotate(180deg);
    }


        
    aside .acordeon .content{
        padding-left: 20px !important;
    }
     aside .acordeon .content.activo{
        margin-bottom: 5px
    }
        
        aside .content a{
            width: 100%;
            float: left;
            color: var(--color-verde); 
            margin: 0;
            padding: 4px 0 0 20px;
            font-size: .85rem
        }
        aside .content a:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 12px;
            height: 12px; 
            background: transparent;
            border: 1px solid var(--color-verde);
            border-radius: 50%;
            margin: auto
        }

        aside .content a:hover:before,
        aside .content a.activo:before{
            background: var(--color-verde) !important;
        }





/*-----------------------------------------------------------------------*/




/* #MAPA's */
.mapa {
    width: 100%;
    height: 480px;
    padding: 0;
}

/* marker */
.leaflet-container .leaflet-marker-pane img {
    width: 52px !important;
    height: 64px !important
}




/*-----------------------------------------------------------------------*/




/* #VIDEO's */
.video {
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
    clear: both;
    width: 100%;
}

.video iframe,
.video embed,
.video objet {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}




/*-----------------------------------------------------------------------*/




/* #ADJUNTO's */
.adjunto {
    float: left;
    text-decoration: none;
    background-repeat: no-repeat;
    background-position: left;
    color: #19181D;
    margin-right: 40px;
    display: inline-flex;
    align-items: center
}

.adjunto:before {
    content: '';
    float: left;
    margin-right: 5px;
    font-size: 1.6rem;
    color: var(--color)
}

.adjunto[href$=".doc"]:before,
.adjunto[href$=".docx"]:before {
    content: '\e923'
}

.adjunto[href$=".pdf"]:before {
    content: '\e92b'
}

.adjunto[href$=".zip"]:before {
    content: '\e934'
}

.adjunto[href$=".xls"]:before,
.adjunto[href$=".xlsx"]:before {
    content: '\e932'
}




/*-----------------------------------------------------------------------*/




/* #TABLE */

/* html responsive */
.row-table {
    width: 100%;
    max-width: 100%;
    float: left;
    overflow-x: auto
}

/* comun */
table {
    border: 1px solid #202020;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    /*table-layout : fixed;*/
}

table caption {
    margin: .5rem 0 .75rem;
}

table tr {
    background: var(--color-fff);
    border: 1px solid #ccc;
    padding: 5px;
}

table tr:first-child td {
    font-weight: 600;
    background: var(--color-eee)
}

table tr:last-child td {
    font-weight: 600
}

table th,
table td {
    padding: .5px;
    text-align: left;
    color: #202020;
    border: 1px solid #ccc
}

table td button{
    float: left !important
}

table th {
    text-transform: uppercase;
}

table strong{
    font-weight: normal !important
}




/*-----------------------------------------------------------------------*/



.alertify-notifier { 
    z-index: 99999999999 !important; 
}

/* #FANCYBOX */
.fancybox__container{
    z-index: 999999999
}

    .fancybox__content {
        max-width: 700px !important;
        background: var(--color-fff);
        border-radius: 20px;
        padding: 30px 25px 30px 30px
    }
    
    /* toolbar */
    .fancybox-navigation {
        position: initial !important
    }

    .fancybox-button,
    .fancybox-infobar__body,
    .fancybox-arrow:after {
        background-color: rgba(255, 255, 255, .8)
    }

    .fancybox-arrow:hover {
        opacity: 1 !important;
    }

    .fancybox-close-small:after {
        color: var(--color-333) !important;
        font-size: 20px
    }

    .fancybox-button svg {
        color: var(--color-verde)
    }

    .fancybox-caption,
    .fancybox-infobar,
    .fancybox-navigation .fancybox-button,
    .fancybox-toolbar {
        visibility: visible !important;
        opacity: 1 !important;
    }

    .fancybox-infobar,
    .fancybox-navigation {
        display: none
    }

    .fancybox-button,
    .fancybox-infobar__body,
    .fancybox-arrow:after {
        background-color: var(--color-000);
    }

    .carousel__button.is-close {
        right: 0 !important;
    }

    .abcRioButtonContentWrapper {
        display: flex !important;
        align-items: center;
    }



/* lightbox gral */
body.embed {
    background-color: transparent;
    padding: 0 10px 0 0;
}
    
    /* cont */
    body.embed #cont {
        padding: 0;
    }

        /* main */
        body.embed #cont main {
            padding: 0;
            padding-top: 0 !important;
            margin: 0;
        }

            /* container */
            body.embed #cont main .container {
                max-width: none;
                padding: 0 !important
            }

                /* aside */
                body.embed aside,
                body.aprende aside{
                    background-color: var(--color-eee);
                    padding: 30px 15px
                }

                    body.embed aside .datos,
                    body.aprende aside .datos{
                        margin-bottom: 20px
                    }
    

                /* tabs */
                body.embed .tab-content {
                    display: flex;
                }

                body.embed .tab-content.activo section{
                    padding: 0 !important
                } 


                body.embed .embed-content {
                    max-width: 1000px;
                    min-width: 350px;
                    margin: 0px auto;
                    min-height: 780px;
                }





                /* relacionados */
                body.embed  .row-relacionados{
                    margin-top: 60px
                }
                body.embed .row-relacionados h6{
                    font-weight: bold;
                    width:100%;
                    float: left;
                }
                body.embed .relacionados .box section {
                    background: #f5f5f5;
                    border-radius: 0 !important;

                }
                body.embed .row-relacionados section{
                    border-radius: 0 !important
                }
                body.embed .relacionados .box section.datos{
                    padding: 0 15px !important
                }

                    body.embed .row-relacionados .box button,
                    body.embed .box footer .cont-btn button{
                        width: 80px !important;
                        color: var(--color-fff) !important;
                        padding: 0 9px !important;
                        font-size: .75rem !important;
                        border-radius: 3px !important;
                        height: 25px !important;
                        margin-top: 20px;
                    }












/* popup custom */
.popup-fbx {
    display: none;
    padding: 0;
    max-width: 420px !important;
    border-radius: 20px !important;
    overflow: hidden;
}

    /* header */
    .popup-fbx header {
        background-color: var(--color-rojo);
        color: var(--color-fff);
        font-size: 1rem;
        margin-bottom: 0;
        text-align: center;
        padding: 10px 15px;
        font-weight: 600
    }

    /* section */
    .popup-fbx section {
        text-align: center;
        padding: 25px
    }

    #entrar.popup-fbx section,
    #crear.popup-fbx section {
        background-color: var(--color-fff) !important
    }

    /* img */
    .popup-fbx img {
        margin: auto;
        display: inline-block;
        margin-bottom: 30px
    }

    /* form */
    .popup-fbx form {
        padding: 0
    }

        .popup-fbx label {
            color: var(--color-gris-2);
            font-size: .85rem
        }

        .popup-fbx input {
            width: 100%;
            float: left;
            background-color: #ededee;
            color: var(--color-666);
            border: none;
            padding: 5px 10px;
            margin-bottom: 15px;
            border-radius: 6px;
            color: var(--color-gris);
        }
        .popup-fbx input::placeholder {
            color: var(--color-gris-2);
        }

        .popup-fbx button:not(.fancybox-close-small) {
            width: auto;
            float: right;
            background-color: var(--color-rojo);
            color: var(--color-fff);
            text-align: center;
            border-radius: 6px;
            border: 1px solid var(--color-rojo);
            cursor: pointer;
            padding: 10px 15px !important
        }

        .popup-fbx .cont-submit button{
            margin-top: 0 !important
        }

        .popup-fbx button:hover {
            background-color: var(--color-fff);
            color: var(--color-rojo) !important
        }

        .popup-fbx .cont-submit {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            float: left
        }

        .popup-fbx .cont-submit a {
            font-size: .8rem
        }



    .popup-fbx section span.registro-link {
        float: right;
        margin-top: 20px;
        color: var(--color-gris-2);
    }

    .popup-fbx .text-center span {
        text-align: center;
        display: inline-block;
        width: 100%;
        margin-top: 0
    }

    .popup-fbx a {
        color: var(--color-gris-2);
        text-decoration: underline;
    } 

    .popup-fbx a:hover {
        text-decoration: none
    }

    .popup-fbx [data-fancybox]:hover{
        text-decoration: underline !important
    }


#crear button:not(.fancybox-close-small),
#entrar button:not(.fancybox-close-small) {
    transform: translateY(45px);
    
}

#crear form button:not(.fancybox-close-small),
#entrar form button:not(.fancybox-close-small),
#crear form button,
#netrar form button{
    transform: translateY(0);
}

#crear span,
#entrar span {
    color: var(--color-gris-2);
}



.abcRioButtonLightBlue {
    width: 100% !important;
    display: inline-flex;
    align-items: center;
}




/* POPUP producto */
.fbx-producto {
    display: block;
    padding: 0;
    max-width: 968px;
    border-radius: 20px !important;
    margin: auto;
    padding-bottom:15px
}

.cocrea .fbx-producto,
.novedades .fbx-producto,
.final .fbx-producto {
    display: block !important;
    margin: auto;
    max-width: inherit;
}

/* header */
.fbx-producto header h3 {
    font-size: 1.5rem;
    margin-bottom: 0;
    width: 100%;
    float: left;
    font-weight: 600
}

.fbx-producto header h6 {
    font-weight: 300;
    font-size: 1rem;
    width: 100%;
    float: left
}

/* article */
.fbx-producto article h5 {
    font-size: 1rem;
    width: 100%;
    float: left
}

.fbx-producto article p+h5 {
    margin-top: 15px;
}

.fbx-producto article p {
    font-size: .9rem
}


/* ASIDE */

/* section */
.fbx-producto aside section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.detalles span {
    font-size: 600;
}


/* article */
.fbx-producto aside article p {
    width: 100%;
    margin-bottom: 5px;
    float: left;
    color: #8c9c91;
    font-size: .85rem;
    line-height: 1.1
}


.fbx-producto aside a {
    width: 100%;
    float: left;
    text-align: center;
    color: var(--color-fff);
    font-weight: bold;
    background-color: #b84d4c;
    padding: 8px;
    border-radius: 7px;
    margin-top: 40px;
    font-size: .8rem;
}

.fbx-producto aside a:hover {
    background-color: var(--color-000);
    text-decoration: none
}

/* fix */
.novedades .fbx-producto aside a {
    width: 100%;
    float: left;
    background-color: transparent;
    padding: 0;
    color: var(--color-verde);
    font-weight: normal;
    border-radius: 0;
    margin: 0;
    border-bottom: 1px solid var(--color-eee);
    padding: 5px 0;
    margin-bottom: 5px;
    text-align: left;
    transition: all .3s ease
}

.novedades .fbx-producto aside a:hover {
    text-decoration: none;
    background-color: var(--color-verde);
    color: var(--color-fff);
    padding-left: 15px;
}


.novedad figure img{
   width: 100% !important
}



/*-----------------------------------------------------------------------*/




/* #COOKIE's */
#cookie {
    width: 100%;
    padding: 15px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: var(--color-gris);
    z-index: 900;
    text-align: center;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    display: none;
}

#cookie p {
    width: auto;
    color: var(--color-fff);
    font-size: .9rem;
    margin: 0;
    display: inline-block;
}

#cookie p a {
    text-decoration: underline;
    color: var(--color-fff)
}

#cookie p a:hover {
    text-decoration: none !important;
    color: var(--color-fff)
}

#cookie button {
    color: var(--color-gris);
    background: var(--color-fff);
    padding: 5px 20px;
    font-weight: 600;
    text-decoration: none;
    border-radius: 4px;
    display: inline-block;
    margin-left: 10px;
    border: none;
    cursor: pointer;
}

/* hover */
#cookie button:hover {
    opacity: .8
}




/*-----------------------------------------------------------------------*/




/* # EFECTO's */

/* scroll */

/*
.scroll #header{
    padding: 5px;
}
    .scroll #logo img{
        height: 30px
    }
    .scroll .correo:before{
        height: 20px
    }


/* otros */
.transition {
    transition: all .4s ease-in
}

.efecto {
    opacity: 0;
    transform: translateX(-10px);
    transition: all .5s ease
}

.efecto-add {
    opacity: 1 !important;
    transform: translateX(0);
}




/*-----------------------------------------------------------------------*/




/* #BARRA SCROLL */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: var(--color-fff); 
}

::-webkit-scrollbar-thumb {
  background: var(--color-verde); 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}




/*-----------------------------------------------------------------------*/




/* #PRECARGA */
#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-fff);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999999999999999
}

#status {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    left: 0; 
    right: 0;
    bottom: 0;
    background: url(../images/preload.png) no-repeat;
    background-size: contain;
    background-position: center;
    margin: auto !important;
    animation-name: status;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    opacity: 1;
    transition: all .4s ease
}

@keyframes status {
  0%   {opacity: .3}
  25%   {opacity: 1}
  50%   {opacity: .3}
  75%   {opacity: 1}
  100%   {opacity: .3}
}




/*-----------------------------------------------------------------------*/




/* CONTENIDO */
.contenido {
    padding: 80px 0 0 0
}

.contenido.fix {
    padding-bottom: 80px;
}

#soy {
    padding-bottom: 70px !important;
}



/* conoce */
#conoce .tit {
    padding-bottom: 5px;
    border-bottom: 3px solid var(--color-fff);
    margin-bottom: 30px
}


/* sub tit */
.subtit h6 {
    width: 100%;
    float: left;
    padding: 6px 0;
    margin: 0;
}

.subtit.blanco * {
    color: var(--color-fff);
}



/* CABEZAL */
.cabezal {
    height: 120px;
    background: var(--color-ddd);
    margin-bottom: 30px;
    background-color: #8c9c91;
    background-repeat: no-repeat;
    background-position: center bottom -20px;
    background-size: 140px
}


.page_descubre .cabezal,
.page_cocrea .cabezal,
.page_vive .cabezal,
.page_aprende .cabezal,
.page_impulsa .cabezal,
.page_cultura_sostenible .cabezal {
    margin-bottom: 0
}




/* container */
.cabezal .container {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center
}

/* tx */
.cabezal * {
    color: var(--color-fff);
    text-align: center !important;
}

.cabezal h2,
.cabezal h3 {
    width:100%;
    float: left;
    text-align: center;
    margin: 0;
    line-height: 1
}

.cabezal h2 {
    font-weight: 800 !important;
    font-size: var(--tit-cabezal)
}
.cabezal h3 {
    font-size: var(--subtit-cabezal);
}


    /* FIX PG NOSOTROS */
    body.nosotros .cabezal{
        height: auto
    }

         body.nosotros .cabezal .container{
            position: absolute;
            top: 0;
            left:0;
            right: 0;
            bottom: 0;
            margin: auto;
         }




/* PRECIO */
.precio {
    border-radius: 6px;
    background-color: #8c9c91;
    color: var(--color-fff);
    font-weight: 600;
    padding: 5px 8px;
    text-align: center;
    width: auto;
    float: left;
    margin-bottom: 10px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0 !important;
    margin-right: 10px;
}

.precio:before {
    margin-right: 10px;
    width: 30px;
    height: 30px;
}





/* REDES */
.redes {
    position: relative;
    width: auto;
    float: right;
}

.redes .ico {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    margin-left: 15px;
    transition: all .3s ease
}

.redes .ico-yt,
.redes .ico-yt:before{
    width: 75px
}


.redes .ico:nth-of-type(1) {
    margin-left: 0
}

.redes .ico:before {
    width: 35px;
    height: 35px;
    background-position: bottom;
    background-size: contain
}

/* hover */
.redes .ico:hover {
    opacity: .55
}





/* WPP */
.wpp {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 30px;
    margin: auto;
    background: url(../images/whatsapp.svg) no-repeat center;
    background-size: contain;
    width: 60px;
    height: 60px;
    z-index: 998
}



/* CONOCE */
#conoce .col-md-6 {
    margin-bottom: 25px;
    display: flex;
    align-items: center
}

/* ico */
#conoce i {
    width: 90px;
    height: 90px;
    float: left;
}

#conoce .ico:before {
    float: left;
    width: 90px;
    height: 90px;
}


/* tx */
#conoce article {
    width: calc(100% - 90px);
    float: left;
    padding-left: 15px;
}

#conoce article * {
    color: var(--color-fff);
}

#conoce article h6 {
    margin-bottom: 10px
}
#conoce article p {
    margin-bottom: 0;
    line-height: 1.2
}



/* NOVEDADES */
.novedad {
    width: 100%;
    float: left;
    background-color: var(--color-fff);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.novedad header,
.novedad article {
    padding: 0 30px;
}

.novedad .galeria {
    padding: 0 !important
}






.movil {
    display: none
}

.pc {
    display: block
}









/* LOGIN perfil */
.login .col--right{
    transform: translateX(-90px)
}

figure.perfil {
    position: fixed; 
    width: 90px;
    height: 90px;
    top: -30px;
    right: 15px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
}

/* img */
figure.perfil img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    object-fit: cover;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: var(--color-fff);
}

/* hover */
figure.perfil:hover {
    background-color: var(--color-fff);
}

figure.perfil:hover img {
    opacity: .6;
}


figure.perfil.active {
    background-color: var(--color-fff);
}

/* ul */
ul.perfil {
    display: none;
    background-color: var(--color-fff);
    position: fixed;
    top: 60px;
    right: 15px;
    width: 110px;
    padding: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

ul.perfil li {
    width: 100%;
    float: left;
    list-style: none;
    padding-top: 5px;
}

ul.perfil li a {
    width: 100%;
    float: left;
    color: var(--color-333) !important;
    padding: 5px;
    font-size: .8rem;
    border-bottom: 1px solid var(--color-ddd);
    cursor: pointer !important;
    line-height: 1.15
}

ul.perfil li a:hover {
    text-decoration: none !important;
    color: var(--color-rojo) !important;
    font-weight: 600;
}

ul.perfil.active {
    display: block;
}







/* MONEDA */
.cont-moneda {
    display: flex;
    align-items: center;
    min-width: 400px !important
}

    .cont-moneda h6 {
        margin-bottom: 0 !important;
        margin-right: 15px;
    }

    .moneda {
        width: auto;
        float: left;
        margin-bottom: 0;
    }

        /* a */
        .moneda .tipo {
            color: var(--color-gris-2);
            font-size: .9rem;
            border: 2px solid var(--color-gris-2);
            padding: 5px 10px;
            font-weight: 500;
            float: left;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            transform: translateX(-2px);
            height: 36px
        }

        /* img */
        .moneda img{
            display: none;
            opacity: 0;
            visibility: hidden
        }

        /* google */
        .moneda .google{
            float: left;
        }

            .moneda .google *{
                color: var(--color-gris-2) !important
            }

            .moneda .goog-te-gadget-simple{
                background: none;
                border: none;
                padding: 0;
                margin: 0;
                color: var(--color-gris-2);
                font-size: .9rem;
                border: 2px solid var(--color-gris-2);
                padding: 5px 10px;
                font-weight: 500;
                float: left;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
                height: 36px

            }

                .moneda .goog-te-gadget-simple a{
                    margin: 0
                }

                .moneda .google span{
                    border: none !important
                }


/* FILTROS */
.filters .add-filter,
.orders .add-order {
    width: 100%;
    float: left;
    padding: 0;
    color: var(--color-gris-2) !important;
    cursor: pointer !important;
}

    .filters .add-filter:hover,
    .orders .add-order:hover {
        color: var(--color-rojo) !important;
    }

    .filters-container,
    .orders-container {
        width: 100%;
        float: left;
        margin-bottom: 20px
    }

    .filters-container ul li,
    .orders-container ul li {
        padding: 5px 10px;
    }

    .items-loader {
        display: inline-block;
        width: 80px;
        height: 80px;
        margin: 0 auto;
    }

    .items-loader:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid var(--color-000);
        border-color: var(--color-000) transparent var(--color-000) transparent;
        animation: items-loader 1.2s linear infinite;
    }

    @keyframes items-loader {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }



/* CARRITO */
.sec-carrito .cont-producto {
    background-color: var(--color-fff);
    border-radius: 20px;
    padding: 30px;
}

    /* fila */
    .fila-pro {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-bottom: 1px solid var(--color-ccc);
        padding: 15px 0;
        justify-content: flex-start;
        margin: 0;
    }

    .fila-pro:nth-child(odd){
        background: #f5f5f5
    }

    .fila-pro a {
        padding: 5px;
        display: flex;
    }

    .fila-pro a i {
        width: 10px;
        height: 10px;
        background-image: url(../images/cerrar.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
    }

    /* img */
    .fila-pro figure {
        position: relative;
        width: 70px;
        height: 70px;
        overflow: hidden;
    }

    .fila-pro figure img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
    }

    /* article */
    .fila-pro article {
        width: 60%;
        padding-left: 15px;
    }

    .fila-pro article h2 {
        color: var(--color-000);
        font-size: 1.15rem;
        width: 100%;
        float: left;
        margin-bottom: 5px;
    }

    .fila-pro article h6 {
        font-size: .9rem;
        width: 100%;
        float: left;
    }

    .fila-pro article p {
        line-height: 1.3;
        font-size: .85rem;
    }


/* precio */
.precio-carrito {
    font-size: 1.2rem;
    font-weight: 600;
    width: 100%;
    text-align: right;
}
    
    /* popup */
    .sec-carrito .popup-fbx.popup-fbx-static {
        display: block !important;
        border-radius: 20px !important;
        float: left;
        overflow: hidden;
    }

    .fila-precio {
        width: 100%;
    }

    .fila-precio .precio-carrito {
        font-size: 1.6rem;
    }

    .fila-precio .precio-carrito small {
        margin-right: 15px;
    }

    .price-row {
        margin: 5px 0;
    }

    .price-item {
        display: flex;
        flex-direction: column;
        text-align: right;
    }

    .quantity-item {
        padding: 10px 8px;
    }



/* COMPARTIR */
.compartir {
    float: right;
    display: flex;
    align-items: center;
    color: var(--color-gris-2);
    font-size: .65rem;
    margin-left: 10px;
    text-transform: uppercase;
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 22px;
    font-family: var(--font-1) !important;
    z-index: 980;
}

    .compartir:after {
        content: '';
        width: 13px;
        height: 13px;
        margin-left: 5px;
        background-image: url(../images/ico-compartir.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        float: left;
    }

        .cont-compartir {
            visibility: hidden;
            opacity: 0;
            height: 0;
            transition: all .3s ease;
            text-align: right;

        }

        .cont-compartir.activo {
            visibility: visible; 
            height: auto;
            opacity: 1;
            padding: 46px 25px 5px 10px;
            background: #f5f5f5;

        }

        .cont-compartir a {
            width: 23px;
            height: 18px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 2px;
        }


        /* ico */
        .cont-compartir .ico:before {
            width: 28px !important;
            height: 18px !important;
            margin: 0 !important;
            margin: auto;
            float: none;
        }
        .cont-compartir .ico:nth-of-type(4):before {
            width: 27px !important;
            height: 17px !important;
        }

        .cont-compartir .ico-correo-comp{
            margin-left: 5px
        }

            .cont-compartir .ico:hover:before{
                opacity: .6 !important
            }


/* DATOS */
.datos {
    padding: 0 25px !important;
    line-height: 1.1;
}

    .datos section{
        /*opacity: .75;*/
        overflow: hidden;
    }

    /* vive fix */
    #vive .datos,
    .grid_vive .box .datos,
    #cultura-sostenible .datos,
   .grid_cultura_sostenible .datos,
    #cocrea .datos,
    #aprende .datos,
    .grid_aprende .box .datos,
    .grid_cocrea .box .datos{
        height: 123px
    }
    
    

    /* tx */
    .datos p{
        margin-bottom: 0;
        /*height: 50px;*/
        overflow: hidden
    } 
    .datos .p-datos{
        margin-top: 8px;
        height: 33.22px;
        overflow: hidden;
        width: 100%;
        float: left
    }
    .datos p,
    .datos span{
       line-height: 1.2;
       font-size: .865rem;
    }

    /* ico */
    .datos .ico {
        width: 100%;
        /*font-size: .78rem;*/
        margin-left: 0;
        display: inline-block;
        align-items: center;
        /*color: #808285;*/
        color: var(--color-000);
    }
        .datos .ico:before {
            width: 12px !important;
            height: 12px !important;
            margin-right: 6px;
            float: left;
            top: 0;
            transform: translateY(2px);
        }



/* box */
.box .datos{
    height: 123px;
    overflow: hidden;
}


/* aside */
aside .datos{
    line-height: 1.4
}
    
    /* ico */
    aside .datos .ico{
        margin-bottom: 6px;
    }

    aside .ico {
        font-size: .765rem;
        display: inline-flex;
        align-items: center;
        margin-left: 5px
    }

    aside .ico.btn{
        margin-left: 0 !important
    }

    aside .ico:before {
        width: 16px;
        height: 16px;
        margin-right: 3px;
        background-size: contain;
    }


aside article {
    padding: 0 25px 
} 

 



/* ETIQUETA */
.etiqueta {
    position: absolute;
    top: 0;
    right: 15px;
    padding: 5px 13px;
    background-color: rgba(0, 0, 0, .8);
    color: var(--color-fff);
    text-align: center;
    color: var(--color-fff);
    z-index: 900;
    font-size: .8rem;
    text-transform: uppercase;
    font-family: var(--font-2);
}



/* PRODUCTO (.box) - estados */
.box {
    display: block;
    width: 100%;
    float: left;
    background-color: var(--color-fff);
    margin-bottom: 40px
}

    /* fix - dentro de un slide */
    .item .box {
        margin-bottom: 0
    }

    /* figure */
    .box figure {
        margin-bottom: 0;
        cursor: pointer;
    }

    /* img */
    .box figure img {
        z-index: 10
    }

    /* video */
    .box .link-video .ico {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 888;
        transition: all .3s ease;
        margin: 0
    }

    .box .link-video .ico-play:before {
        width: 50px;
        height: 50px;
        background-size: 20px;
        display: block;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 50%;
        transition: all .3s ease
    }


    /* header */
    .box header {
        padding: 15px 25px;
    }

        .box header h3 {
            width: calc(100% - 95px);
            min-height: 40px;
            float: left;
            color: var(--color-000);
            font-weight: bold;
            margin-bottom: 0;
            line-height: 1.1;
            margin-bottom: 2px;
            
            font-size: 1.2rem;
            overflow: hidden 
        }

        #cultura-sostenible .box header h3,
        #cultura-sostenible .box header h6,
        #cultura-sostenible .box .datos p {
            font-family: var(--font-4);
        }


        .col-lg-3 .box header{
            min-height: 86px
        }

        .box header h6 {
            float: left;
            color: var(--color-000);
            margin-bottom: 0;
            font-style: italic;
            width:  calc(100% - 90px);
            font-style: normal;
            font-size: .9rem;
            height: 16px;
            overflow: hidden;
            font-weight: 400
        }


    /* article */
    .box article {
        padding: 10px 25px
    }

        .box article p {
            font-size: .85rem;
            line-height: 1.3;
        }

        .resumen {
            width: 100%;
            float: left;
            color: var(--color-gris-2);
            height: 55px;
            overflow: hidden;
        }



    /* footer */
    .box footer {
        position: relative;
        padding: 15px 25px;
        display: block;
        align-items: center;
        justify-content: space-between;
        min-height: 40px;
    }


    /* cont btn */
    .box footer .cont-btn {
        width: auto;
        max-width: 150px;
        min-width: 110px;
        min-height: 35px;
        // float: left;
    }

    .box footer .cont-btn a,
    .box footer .cont-btn button {
        width: auto !important;
        padding: 5px 15px;
        margin: 0 0 5px 0 !important;
        /*font-size: .75rem !important;*/
    }

    /* estados */ 
    .agotado,
    .agotado:hover {
        color: var(--color-fff) !important;
        cursor: default !important;
        opacity: 1 !important;
        background-color: var(--color-gris-2) !important;
        border: 1px solid var(--color-gris-2) !important
    }

    /* footer */
    .box footer .cont-btn .ico-aportar:before {
        display: block !important;
        content: '';
        float: left;
        width: 16px;
        height: 16px;
        margin-right: 5px;
    }


    /* cont ico */
    .box footer .cont-ico {
        width: calc(100% - 110px);
        padding-left: 10px;
        float: right;
        top: 4px;
    }
    
    .box footer .btn-conoce{
        position: absolute;
        height: 18px;
        top:0;
        right:25px !important;
        bottom:0;
        margin: auto;
        color: var(--color-verde) !important
    }

    .box footer .cont-ico .ico:nth-of-type(1) {
        margin-left: 0 !important;
    }


        /* tx */
        .box footer .cont-ico * {
            color: var(--color-verde);
            font-size: .75rem;
        }


        /* ico */
        .box footer .cont-ico .ico {
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
        }

        .box footer .cont-ico .ico:before {
            width: 16px;
            height: 16px;
            float: left;
            margin-right: 5px;
        }

        /* fix usuario */
        .ico-usuario {
            display: none !important;
        }

        /* fix conoce */
        .box footer .ico.btn-conoce {
            font-weight: 600 !important;
            text-decoration: underline;
            text-transform: uppercase;
            min-height: 18px;
            display: inline-flex;
            align-items: center;
            top: 1px;
            font-size: .65rem !important;
            float: right;
            margin-right: 0;
        }

        .box footer .ico.btn-conoce:before {
            display: none;
        }

        .box footer .ico.btn-conoce:hover{
            color: var(--color-rojo) !important
        }

        .estado-3{
            opacity:.5
        }

    

        .box .ico:nth-of-type(2):before {
            width: 16px;
            height: 16px;
           /* margin-right: 3px*/
        }

        /* hover */
        .box figure:hover .ico:before {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }

        .box.estado-1 figure:hover .ico:before {
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }

        .proximamente{
            cursor: pointer !important
        }



.producto .col-12 {
    text-transform: uppercase;
    font-weight: bold;
}




/*-----------------------------------------------------------------------*/



.embed-content fieldset,
.embed-content legend{
    padding: 0 !important;
    margin: 0 !important
}

.radio.activo{
    opacity: 1 !important
}



/* estilos que estaban en index (30-11-2021) */

        .login .form-search{
            left: 120px;
        }

        .redes .ico-yt:before{
            width: 75px;
        }


        .sec-precios button{
            text-transform: uppercase;
            font-weight: bold;
        }
        button.btn{
            border: 1px solid  var(--color-verde) !important;
        }
        button.btn:hover{
            background-color: var(--color-000) !important;
            border: 1px solid var(--color-000) !important;
        }

        .sec-precios .cantidad{
            width: 80px !important;
        }

        

        .sec-precios label span{
            text-transform: capitalize;
        }

        .swiper-pagination-bullet-active{
            border: 1px solid rgba(0, 0, 0, .7);
        }
        .swiper-pagination-bullet:hover{
            background-color: var(--color-verde);
            border:  1px solid var(--color-verde);
        }
 

        /* carrito */
        .sec-carrito .cont-producto{
            border-radius: 15px !important;
            padding: 20px !important;
            border: 1px solid rgba(0, 0, 0, 0.125);
        }

        .producto-agotado{
            border: 1px solid rgba(0,0,0,.133);
            border-radius: 3px;
            height: 23px;
            text-align: center;
        }
        .producto-agotado span{
            text-transform: uppercase !important;
        }
        aside .producto-agotado{
            display: block;
            width: auto;
            margin: 10px auto;
        }
        .sec-precios .producto-agotado{
            align-items: center;
            display: inline-flex;
            position: absolute;
            right: 0;
            width: 80px !important;
        }
       

        .fila-pro{
            min-height: 75px;
            padding: 15px;
            border-bottom: 1px solid var(--color-eee)
        }
        .fila-pro article h2{
            font-size: 1rem;
        }
        .fila-pro figure{
            width: 55px;
            height: 53.67px;
        }
        .price-row{
            margin:  0;
        }

        .fila-pro article{
            width: 240px;
            padding-left: 10px;
            border-right: 1px solid var(--color-eee);
        }

        .fila-pro section{
            width: calc(100% - 300px);
            padding-left: 15px;
            min-height: 53px;
            text-align: right;
            top: -2px;
            position: relative;
        }

        .fila-pro *{
            color: var(--color-000) !important;
        }

        .precio-compra{
            font-size: .85rem;
        }
        .precio-carrito{
            font-size: .9rem;
            font-weight: 600;
        }
        .quantity-item{
            padding:  0;
            margin-left: 3px;
        }
        .precios, 
        .subtotal{
            width: calc(100% - 25px);
            float: left;
        }
        .subtotal{
            padding-top: 8px;
        }



        .sec-precios{
            margin-bottom: 30px;
        }


        .fila-precio{
            margin: 40px 0 0 0;
            background-color: var(--color-eee);
            overflow: hidden;
            /*border-bottom-right-radius: 15px;
            border-bottom-left-radius: 15px;*/
        }
        .fila-precio .precio-carrito{
            font-size: 1.45rem;
            font-weight: 600;
            padding:  15px 0;
            overflow: hidden;
            border-bottom-right-radius: 15px;
            border-bottom-left-radius: 15px;
            background-color: var(--color-eee);
            padding-right: 40px
        }
        .precio-carrito{
            color: var(--color-000);
        }

        .subtotal h6{
            margin-bottom: 0
        }

        .del{
            width: 16px;
            height: 16px;
            color: var(--color-000);
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
                    }
        .fila-pro a i{
            width: 15px;
            height: 15px;
            color: var(--color-rojo);
        }


        .sec-carrito .list-group{
            border-radius: 15px;
            padding: 20px;
            background-color: var(--color-fff);
            border: 1px solid rgba(0, 0, 0, 0.125);
            margin: 0
        }
        .sec-carrito .list-group .list-group-item {
            border:  none;
            padding: 0;
        }
        .sec-carrito .list-group .list-group-item h2{
            font-size: 1rem;
            margin: 0 0 15px 0;

        }

        .mercadopago-button,
        .tb-button {
            background-color: var(--color-rojo) !important;
            font-weight: 600 !important;
            text-transform: uppercase !important;
            border-radius: 6px !important;
        }
        .mercadopago-button:hover,
        .btn-terminar:hover,
        .tb-button:hover{
            background-color: var(--color-000) !important;
        }


.novedades .tab-content .col-md-8{
    margin-bottom: 30px;
}

.novedades .swiper-slide:hover img{
    opacity: .8;
}


/* perfil */
#registrar_user_form{
    max-width: 70%
}

    #registrar_user_form button{
        float: left !important;
        margin-top: 15px
    }



/* Mis compras */
section.cont-pedidos{
    border-radius: 20px;
    padding: 20px;
    background: var(--color-fff)

}
.pedido-item .col-12 section{
    background: var(--color-eee)
}

.pedido-header strong{
    font-size: .8rem
}

.pedido-item,
.pedido-cantidad{
    width: 100%;
    float: left;
    display: flex;
    align-items: center
}
.pedido-item:nth-child(even){
    background: #f5f5f5
}
 

    .col-id{
        width: 60px
    }
    .col-fecha{
        width: 140px;
    }
    .col-estado{
        width: 26%;
    }
    .col-monto{
        width: 26%;
    }
    .col-pedido{
        width: 100px;
    }
        .col-pedido button{
            width: 50px !important
        }

    .col-comp{
        width: 180px;
    }

    .pedido-item .item-col{
        padding: 5px 15px;
        line-height: 1;
        display: flex;
        align-items: center
    }



.pedido-cantidad{
    margin-top: 15px
}

.alertify .ajs-dialog.cd-alert-dialog{
    position: absolute;
    display: flex;
    bottom: 0;
    width: 500px;
    left: 0;
    right: 0;
}
.alertify .ajs-dialog.cd-alert-dialog .ajs-body {
    margin: auto;
    text-align: center;
    width: 100%;
    min-height: 185px;
    height: 100%;
}

.alertify .ajs-dialog.cancel-order-dialog{
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    max-width: 500px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-height: 200px;
}

/* fix ico precio carrito popup */
.ico-carrito-precio.add-cart:before{
    display: block !important;
    width:22px;
    height: 22px
}

.box footer .cont-btn .ico-carrito-precio.add-cart:before{
    display: none !important;
}

.fbx-producto aside a.ico-carrito-precio.add-cart{
    margin: 0 0 15px 0 !important
}
 


/* aprende pg */
.item-contenido-unidad{
    width: 100%;
    float: left;
    margin-bottom: 40px
}

    .item-contenido-subunidad{
        width: 100%;
        float: left;
        margin-bottom: 20px
    }

    /* material */
    .item-material{
        width:100%;
        float: left;
    }

        .item-material p{
            margin-bottom:0
        }

        .item-material a{
            color: var(--color-333);
        }
        .item-material a:hover{
            color: var(--color-verde);
            text-decoration: underline
        }
 

    /* video */
    .video_embed{
        margin-bottom: 15px
    }

/* tab 4 */
.tabla{
    width:100%;
    float: left;
    border:1px solid var(--color-666)
}

    .tabla .tr{
        width:100%;
        float:left;
        display: table;
        align-items: center;
        border-bottom: 1px solid var(--color-666)
    }

    .tabla .tr.head span{
        font-weight: bold
    } 

    .tabla .td{
        width:auto;
        height: 100%;
        border-right: 1px solid var(--color-666);
        display: table-cell;
        padding: 10px;
        color: var(--color-666)
    } 

        .tabla .td h1,
        .tabla .td h2,
        .tabla .td h3,
        .tabla .td h4,
        .tabla .td h5,
        .tabla .td h6{
            font-size: 1rem
        }
        .tabla .td p{
            line-height: 1.4;
            font-size: .9rem
        }

    .tabla .tr .td:nth-child(1){
        width: 30%
    }
    .tabla .tr .td:nth-child(2){
        width: 15%
    }
    .tabla .tr .td:nth-child(3){
        width: 55%;
        border-right: none
    }

    .tabla .td .descarga{
        padding-left: 35px;
        color: var(--color-666);
        text-decoration: underline
    }

    .tabla .td .descarga:before{
        content: '';
        width:25px;
        height: 25px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin-right: 5px;
        background-image: url(../images/ico-descarga-tabla.jpg);
        background-position: left center;
        background-size: contain;
        background-repeat: no-repeat
    }

     .tabla .td .descarga:hover{
        color: var(--color-verde)
    }



/* Cultura sostenible (home) */
#cultura-sostenible{
    padding-bottom: 0;
}

    
    #cultura-sostenible .galerias{
        margin-bottom: 40px
    }


/* conoce (home) */
#conoce{
    margin-top: 60px;
    padding-bottom: 60px;
}

.order-form {
    display: none;
}
.order-form.activo {
    display: block;
}

.user-img-thumbnail {
    height: 200px;
    width: 200px;
    object-fit: cover;
}

video::-webkit-media-controls {
    display: none;
}

.form-loader{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 25px;
    justify-content: center;
    align-items: center;
    background-color: #cccccc44;
    display: none;
}
.form-loader.active {
    display: flex;
}
.form-loader.active .pulse {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #000000dd;
    box-shadow: 0 0 0 0 #000000cc;
    animation: pulse-c6zlskmd 1s infinite;
 }
@keyframes pulse-c6zlskmd {
    100% {
       box-shadow: 0 0 0 33.6px #0000;
    }
 }

.g-recaptcha{
    float: inherit;
}




/* UPDATE JUNIO 2023 */


/* #galeria */
.crea--galeria{
    padding: 0;
    background-color: #fff;
    
    margin-bottom: 80px;
}


    .crea--galeria .swiper {
      width: 100%;
      height: 100%;
    }

    .crea--galeria .swiper-slide {
      text-align: center;
      font-size: var(--p);
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .crea--galeria .gallery .swiper-slide figure { 
        position: relative;
    }
        .crea--galeria .gallery .swiper-slide figure img {
          position: absolute;
          top: 0;
          left: 0;
        }

    .crea--galeria .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .crea--galeria .swiper {
      width: 100%;
      height: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    .crea--galeria .swiper-slide {
      background-size: cover;
      background-position: center;
    }

    .crea--galeria .thumbs .swiper-wrapper{
        grid-gap: 42px;
        padding-left:21px !important
    }

    .crea--galeria .gallery {
      height: 80%;
      width: 100%;
    }

    .crea--galeria .gallery-thumbs {
      height: 75px;
      box-sizing: border-box;
      padding: 0;
    }

    .crea--galeria .gallery-thumbs .swiper-slide {
      width: 75px;
      height: 75px;
      opacity: 0.6;
      border-radius: 50%;
      overflow: hidden;
    }

    .crea--galeria .gallery-thumbs .swiper-slide-thumb-active {
      opacity: 1;
    }

    .crea--galeria .swiper-wrapper {
        justify-content: center
    }

    .crea--galeria .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }






/* #cutlura sostenible */
.page_cultura_sostenible .embed-content header *,
.page_cultura_sostenible .embed-content article * {
    font-family: var(--font-4); 
}




/* #relacionados */
.crea--relacionados{
    margin-top: 60px;
}
.crea--relacionados h5{
    width:100%;
    float:left;
    padding-bottom: 25px;;
}
.crea--relacionados h5:after{
    content: '';
    width: 100%;
    height: 1px;
    background-color: #333;
    position: absolute;
    left:0;
    bottom: 0;



}



/* #embed content */ 
.embed-content {
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
    padding: 30px;
}

.page_cultura_sostenible .embed-content {
    background-color: transparent
}





/* nueva estructura set 2023 */

.crea--contenedor {
    background-color: #fff;
    padding: 100px 190px;
    margin-bottom: 100px;
    width: 100%;
    float: left;
}
.page_cultura_sostenible .crea--contenedor {
    background-color: transparent
}
.page_cultura_sostenible .crea--galeria {
    background-color: transparent;
}


.crea--contenedor section.d--grid {
    grid-template-columns: 1fr 270px;
    grid-gap: 60px;
}


    /* col--left */
    .crea--contenedor .crea--col-left .sec-precios {
        margin-top: 100px;
    }

    /* col--right (aside) */
    .crea--contenedor .crea--col-right aside {
        background-color: #f5f5f5;
        padding-bottom: 25px;
    }

        /* btn */
        aside .btn {
            font-size: 16px;
            height: 24px;
            margin-bottom: 30px;
        }

        aside .datos {
            margin-top: 30px;
        }

        /* tx */
        aside p,
        aside .ico {
            font-size: 15px;
        }

        aside p strong,
        aside p b {
            font-weight: 600;
        }

        aside .datos .ico:before {
            transform: translateY(4px);
        }


/* separadores */
.sep--100 {
    width: 100%;
    float: left;
    height: 100px;;
}

.h-captcha {
    position: relative;
    display: inline-block;
}

.page-link {
    color: var(--color-000) !important;
}

.active>.page-link, .page-link.active {
    background-color: var(--color-gris-2) !important;
    border-color: var(--color-gris-2) !important;
}

.page-link:hover {
    background: var(--color-000) !important;
    border: 1px solid var(--color-000) !important;
    color: var(--color-fff) !important;
}