@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Barlow+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Lato:wght@300;400;700;900&display=swap');

// stylelint-disable value-keyword-case
$font__main: 'Barlow', sans-serif;
$font__h: 'Barlow', sans-serif;

// stylelint-enable value-keyword-case
$font__code: monaco, consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", courier, monospace;
$font__line-height-body: 1.5;
$font__line-height-pre: 1.6;